<template>
  <div>
      <div class="body">
          <TabView ref="tabview1">
              <TabPanel id="tab-panel" header="School Information">
                  <SchoolInfo />
              </TabPanel>
              <TabPanel id="tab-panel" header="Contact Details">
                  <ContactDetails />
              </TabPanel>

              <TabPanel id="tab-panel" header="Agreement Letter">
                  <AgreementLetter />
              </TabPanel>
              
              <TabPanel id="tab-panel" header="Security Settings">
                  <SecuritySettings />
              </TabPanel>
              <TabPanel id="tab-panel" header="General Settings">
                  <p><GeneralSettings /></p>
              </TabPanel>
          </TabView>
      </div>  
  </div>
</template>

<script>
  import TabView from 'primevue/tabview';
  import TabPanel from 'primevue/tabpanel';
  import SecuritySettings from "./SecuritySettings.vue";
  import SchoolInfo from "./SchoolInfo.vue";
  import ContactDetails from "./ContactDetails.vue";
  import GeneralSettings from './GeneralSettings.vue';
  import AgreementLetter from './AgreementLetter.vue';
  
export default {
  name: "UserSettings",
  components: { TabView, TabPanel, SecuritySettings, SchoolInfo, ContactDetails, GeneralSettings,AgreementLetter },
  data() {
    return {
      
    }
  }  
}
</script>

<style lang="scss" scoped>
  .body{
    width: 100%;
    
  }
  
//   ::v-deep(#tab-panel.p-tabview-nav) {
 
//   .p-tabview-panel {
//     margin-left: 300px;
//     margin-right: 300px;
//   }
// }
</style>