<template>
    <div class="position-relative">
      <div id="toBar"
        style="position: absolute; z-index: 2"
        class="bg-light shadow-sm w-100 px-4 pt-2"
      >
        <h6 class="text-dark w-100">
          {{ selectedChatInfo.name }}
          <a
            title="reply"
            href="#"
            class="text-dark badge bg-warning"
            @click="replyTo"
          >
            &lt; {{ selectedChatInfo.sender_email }} &gt;
          </a>
          <Button class="btn btn-sm mx-3" @click="replyTo"
            >Reply</Button
          >
          <i class="icon-refresh rotating"></i>
          <span
            tabindex="0"
            id="reload-btn"
            :class="{ rotate: rotateReload }"
            @click="handleReloadClick"
            >&#x21bb;</span
          >
        </h6>
      </div>
      <div
        ref="chatContainer"
        class="p-4 overflow"
        style="overflow-y: auto; height: 68vh"
      >
        <br />
        <TransitionGroup name="list" tag="div" class="chat-container mt-4">
          <div
            v-for="(message, index) in all_message"
            :class="messageClass(message)"
            :key="index"
          >
            <p v-html="message.message"></p>
            <p v-if="message.attachement">
              <img :src="message.attachement" alt="attachment" />
            </p>
            <span class="time">{{ formatDateTime(message.created_at) }}</span>
          </div>
        </TransitionGroup>
        <!-- Add more messages here -->
      </div>
    
      <div id="text-container" :class="{'message-input-container':showInput} " style="width: 100%;position:absolute;bottom:-48%;transition: bottom 0.3s cubic-bezier(0.55, 0.53, 0.68, 1.26) 0s;">
        <button v-show="!showInput" class="toggle-button btn btn-warning text-dark" @click="toggleInput">Compose</button>
        <div class="message-input-wrapper p-4">          
          <div class="input-area">
            <div class="attachment-area">
              <button  class="attachment-button btn-white bg-white" @click="openFileInput">              
                <svg height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 280.067 280.067" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#ffbc05;" d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429 c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0 s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394 c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599 c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403 c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485 c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098 l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"></path> </g> </g></svg>
              </button>
            </div>
            <div class="w-100">
              <v-multiselect v-model="selectedEmails" :options="emails" filter optionLabel="receiver_email" placeholder="Email To"
              :maxSelectedLabels="3" class="w-100" />   
              <Editor
                v-model="message"
                class="message-editor"
                editorStyle="height: 100px; width:100% "
                placeholder="Type a message"
              />
            </div>
              <button @click="send" class="send-button">Send</button>
            </div>
            <input
              type="file"
              ref="fileInput"
              class="hidden-file-input d-none"
              @change="handleFileUpload"
            />
          </div>
        </div>    
      </div>
  </template>
  
  
  <script>
  import { get,postFormData,post } from "@/api/client";
  import { showModal, showToast} from '@/plugins/modal'
  import { useChatsStore } from "@/stores/chats";
  import Editor from "primevue/editor";
  import MultiSelect from "primevue/multiselect";
  export default {
    name: "ViewMail",
    components: {Editor,'v-multiselect':MultiSelect},
    data() {
      return {
        selectedEmails:[],
        emails:[],
        all_message: {},
        selectedChatInfo: {},
        rotateReload: false,
        message: '',
        showInput: false,
        someFiles:[],      
        attchments:[],
      };
    },
    async created() {
      this.updateMessages();
    },
    mounted(){
      this.$nextTick(()=>{
        // Add a click event listener to the document
        // Get the container element
        const container = document.getElementById('text-container');
        const toBar = document.getElementById('toBar');
        
        document.addEventListener('click', (event)=> {
          // Check if the click is outside the container
          if (!container.contains(event.target) && !toBar.contains(event.target)) {
            // Click is outside the container, handle the event here
            this.showInput = false
            
            // Perform any desired actions or trigger functions
            // ...
          }
        });

        setTimeout(() => {        
        
        this.emails = useChatsStore().chats.map((item)=>{
          return {
            receiver_id: item.sender_id,
            receiver_email: item.sender_email,
            user_type: item.user_type,
          }         
        })
        if(this.chatStore.selected !== null){          
            this.selectedEmails.push({
              receiver_id: this.chatStore.selected.sender_id,
              receiver_email: this.chatStore.selected.sender_email,
              user_type: this.chatStore.selected.user_type,
            })
            this.chatStore.setSelected(null)
          }
      }, 1000);
      })
    },
    methods: {
      replyTo(){
        this.showInput = true
        this.selectedEmails.push({
             receiver_id: this.selectedChatInfo.sender_id,
             receiver_email: this.selectedChatInfo.sender_email,
             user_type: this.selectedChatInfo.user_type,
           })   
      },
      async send(){
        
        /* const confirm = await showModal({
           text:"Are you sure you want to send",
           confirmText:'Yes',
           cancelText:'No'
         })
         if(!confirm){
           return false
         } */
  
           const res = await post(this.$endpoints.sendMessage,{
               receivers: this.selectedEmails.map((item)=>{
                 return {
                   receiver_id: item.receiver_id,
                   user_type: item.user_type
                 }
               }),        
               message:this.message,
               attachments:this.attachments
             })
             if(res){    
              this.showInput = false    
                this.updateMessages()              
               showToast(res.message)            
             }
      },
      openFileInput() {
        this.$refs.fileInput.click();
      },
      async handleFileUpload(e) {      
        //this.$emit('input', e.target.files[0])
        if (e.target.files[0].size > 1097152) {
          showModal({text:"File is too big!"});
          return;
        }
        const formData = new FormData();
        formData.append('file', e.target.files[0]);        
        formData.append('folder_name', "Programs");        
        const res = await postFormData(
            "api/file_upload",
            formData,
          );                     
        this.attchments.push(res);
        this.someFiles.push(e.target.files[0]); // Handle the uploaded file      
      },
      toggleInput() {
        this.showInput = !this.showInput;
      },
  
      scrollToBottom() {
        const chatContainer = this.$refs.chatContainer;
        requestAnimationFrame(() => {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        });
      },
      async updateMessages() { 
        let chats = [];
   
        this.selectedChatInfo = JSON.parse(localStorage.getItem('chatInfo'))
        chats = JSON.parse(localStorage.getItem('chats'))
        this.emails = chats.map((item)=>{
            return {
              receiver_id: item.sender_id,
              receiver_email: item.sender_email,
              user_type: item.user_type,
            }         
          })                
        let res = await get(
          `${this.$endpoints.chats}?sender_id=${this.selectedChatInfo.sender_id}&user_type=${this.selectedChatInfo.user_type}`,        
        );
        this.all_message = res.message;
        setTimeout(() => {
          this.scrollToBottom();
        }, 50);
        return true;
      },
      async handleReloadClick() {
        this.rotateReload = true;
        await this.updateMessages();
        this.rotateReload = false;
      },
  
      redirectToCreateInbox() {
        this.$router.push("/inbox/create");
        useChatsStore().setSelected(this.selectedChatInfo);
      },
  
      messageClass(message) {      
        if (
          message.user_type == 'highSchoolEmployee'        
        ) {
          return "message receiver";
        } else {
          return "message sender";
        }
      },
      formatDateTime(dateTimeStr) {
        if (!dateTimeStr) {
          return "Empty";
        }
        const dateObj = new Date(dateTimeStr);
        const today = new Date(); // Current date
  
        // Check if the date has passed today
        if (dateObj.toDateString() === today.toDateString()) {
          let hours = dateObj.getHours();
          let minutes = dateObj.getMinutes();
          let period = "AM";
  
          // Convert hours to 12-hour format and determine AM/PM
          if (hours >= 12) {
            period = "PM";
            if (hours > 12) {
              hours -= 12;
            }
          }
  
          // Pad single-digit minutes with a leading zero
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
  
          return hours + ":" + minutes + " " + period;
        } else {
          // Return the full date in "DD/MM/YYYY" format
          const day = dateObj.getDate();
          const month = dateObj.getMonth() + 1;
          const year = dateObj.getFullYear();
          return day + "/" + month + "/" + year;
        }
      },
    },  
  };
  </script>
  <style scoped>
  .chat-container {
    padding: 10px;
  }
  
  .message {
    padding: 8px;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  
  .sender {
    background: white;
    border-top: 2px solid #cacfc7;
    border-bottom: 2px solid #cacfc7;
  }
  
  .receiver {
    justify-content: flex-start;
    position: relative;
  }
  .message.receiver::after {
    content: "You";
    /* Additional styles for the pseudo-element */
    position: absolute;
    bottom: 20px;
    right: 10px;
    font-size: 12px;
    color: #888;
  }
  
  .message.sender::after {
    content: v-bind("selectedChatInfo.user_type");
    /* Additional styles for the pseudo-element */
    position: absolute;
    bottom: 20px;
    left: 10px;
    font-size: 12px;
    color: #888;
  }
  
  .content {
    background-color: #dcf8c6;
    color: #000;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
  }
  
  .time {
    font-size: 12px;
    color: #888;
    margin-left: 10px;
  }
  .chat-drop-enter-active,
  .chat-drop-leave-active {
    transition: all 0.5s;
  }
  
  .chat-drop-enter,
  .chat-drop-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .chat-message {
    margin-bottom: 10px;
  }
  .list-enter-active,
  .list-leave-active {
    transition: all 0.4s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
  
  #reload-btn {
    cursor: pointer;
    display: inline-block;
  }
  
  .rotate {
    animation: rotating 1s linear infinite;
    -webkit-animation: rotating 1s linear infinite;
  }
  
  @keyframes rotating {
    0% {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Add your existing CSS styles here */
  
  .input-area {
    display: flex;
    align-items: center;
  }
  
  .attachment-area {
    margin-right: 10px;
  }
  
  .attachment-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  
  .message-editor {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 150px;
  }
  
  .send-button {
    width: 80px;
    height: 40px;
    border: none;
    background-color: #4caf50;
    color: #fff;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: transform 0.3s ease;
  }
  
  .message-input-container{
    bottom:-10% !important;
  }
  
  .message-input-wrapper {
    background: #00000042;
    border-radius: 5px 5px;
  }
  
  .w-100{
    
  }
  </style>