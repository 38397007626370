import axios from "axios";
import Swal from "sweetalert2";
import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";

const HTTP = axios.create({
  baseURL: `https://crm.paramountstudents.com/`,
  headers: {
    "Content-Type": "application/json",
  },
});

function requestLogout() {
  Swal.fire({
    title: 'Network Error',
    text: 'Probably session expired. Logout?',
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: 'yes',
    denyButtonText: `No`,
  }).then((result) => {
    if (result.isConfirmed) {
      const store = useAuthStore();
      store.logout();
    }
  });
}

export const get = async (resource) => {
  const token = localStorage.getItem("user");
  const store = useUserStore();
  store.isLoading = true;
  try {
    const response = await HTTP.get(resource, { headers: { Authorization: token ? `Bearer ${token}` : "" } });
    store.isLoading = false;
    if (response.status === 200 || response.data?.error === false) {
      return response.data;
    }
    Swal.fire(response?.message ?? response.data?.responseBody ?? "Something went wrong!");
  } catch (e) {
    store.isLoading = false;
    if (e?.message === "Network Error") {
      requestLogout();
    } else if (e.response.status === 401 || e.response.status === 302) {
      Swal.fire('Session expired').then(function () {
        const store = useAuthStore();
        store.logout();
      });
    } else {
      const message = [
        typeof e.response?.data == 'object' ? '' : e.response?.data,
        e.response?.data?.message,
        e.response?.data?.responseBody,
      ];
      Swal.fire(message.join());
    }
    return false;
  }
};

export const post = async (resource, data) => {
  const token = localStorage.getItem("user");
  const store = useUserStore();
  store.isLoading = true;
  try {
    const response = await HTTP.post(resource, data, { headers: { Authorization: token ? `Bearer ${token}` : "" } });
    store.isLoading = false;
    let obj = typeof response.data === 'object' ? response.data : {};
    if (Object.keys(obj)?.includes('data')) {
      return response.data.data.responseBody;
    } else if (Object.keys(obj)?.includes('status') === "success") {
      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    store.isLoading = false;
    if (e?.message === "Network Error") {
      requestLogout();
    } else if (e.response.status === 401 || e.response.status === 302) {
      Swal.fire('Session expired').then(function () {
        const store = useAuthStore();
        store.logout();
      });
    } else {
      const message = [
        typeof e.response?.data == 'object' ? '' : e.response?.data,
        e.response?.data?.message,
        e.response?.data?.responseBody,
      ];
      Swal.fire(message.join());
    }
    return false;
  }
};
