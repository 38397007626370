<template>
  <div class="container h-auto px-0 py-3">
    <div class="applications-main">
      <div>
        <StudentProfile :data="data" />
      </div>

      <div>
        <StudentContact :data="data?.contact"/>
      </div>
      <div>
        <StudentEducation :data="data?.education"/>
      </div>
      <div>
        <StudentDocument :data="data?.document" :student="data"/>
      </div>
    </div>

  </div>
</template>


<script>
import StudentContact from '@/components/StudentApplication/StudentContact.vue';
import StudentEducation from '@/components/StudentApplication/StudentEducation.vue';
import StudentProfile from './StudentProfile.vue';
import StudentDocument from './StudentDocument.vue';


export default {

  name: "StudentBio",



  components: { StudentContact, StudentEducation, StudentProfile, StudentDocument },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stuForm: false,
      selectedReview: null,
      AppliactionReview: ['No Apply yet', 'Applying', 'Got Admissions'],

    };



  }

}
</script>

<style scoped>
.container {
  margin-bottom: 60px;
}

</style>