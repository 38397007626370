import { createSSRApp,reactive } from "vue";
import App from "./App.vue";
import router from "./router";

import { createPinia } from "pinia";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../src/assets/styles/app.css";
import "../src/assets/styles/color.css";
import "../src/assets/styles/buttons.css";
import "../src/assets/styles/utilities.css";
import "../src/assets/styles/animation.css";
import GoogleTranslateSelect from "@google-translate-select/vue3";
import PrimeVue from "primevue/config";
import { globals } from "./stores/globals";
//import 'primevue/resources/themes/saga-blue/theme.css';       //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; // icons
import Editor from 'primevue/editor';
import Sidebar from 'primevue/sidebar';
import "../src/assets/theme.css";
import VOtpInput from "vue3-otp-input";
/* import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VuePhoneNumberInput from "vue-phone-number-input"; */

import ConfirmationService from "primevue/confirmationservice";
import InputText from "primevue/inputtext";
import ToastService from "primevue/toastservice";
import { endpointRoutes } from './stores/endpointRoutes';
import MultiSelect from "primevue/multiselect";
import initializeEcho from './plugins/pusher.js'; 
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
const app = createSSRApp(App);
const pinia = createPinia();
app.use(VueSvgInlinePlugin);
//app.component("vue-phone-number-input", VuePhoneNumberInput);
app.component("v-editor", Editor);
app.component("v-multiselect", MultiSelect);
app.component("v-sidebar", Sidebar);
app.component('v-GoogleTranslateSelect',GoogleTranslateSelect)

app.use(pinia); // Create the root store
app.use(router);

app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.component("InputText", InputText);
app.config.globalProperties.$endpoints = endpointRoutes;
const deepGlobals = reactive(globals);
deepGlobals.notification = reactive(deepGlobals.notification);
app.config.globalProperties.$globals = deepGlobals;
app.component("v-otp-input", VOtpInput);
//window.baseUrl =`http://localhost:8000/`;
window.baseUrl = `https://crm.paramountstudents.com/`;
//window.baseUrl = `https://crm-demo.paramountstudents.com/`;
app.mount("#app");

if(!window.Echo && localStorage.getItem('user')){
    initializeEcho(window.baseUrl,localStorage.getItem('user'));
}
// Append the script element to the document's body or head
    
    
