<template>
    <div class="row w-90 mx-auto">
        <!-- <div class="container-fluid h-100 p-0" style="background-color: aqua"> -->
        <div class="px-4 py-3">
            <div class="col-12 d-flex justify-content-between">
                <h3 class="fw-bold">Associations</h3>
                <!--
                    <button @click="showForm()" :class="{ active: trackForm }"
                        class="filter-btn btn text-dark px-5 py-2 d-flex ripple">
                        <img :src="require('/src/assets/images/filter.svg')" />
                        Filter
                    </button> 
                -->
            </div>
        </div>
        <!-- 
            <div v-if="trackForm" class="px-4">
                <FilterOptions :statuses="trackingStatus" @onSearch="filterChange" />
            </div>
        -->
        <hr />
        <div class="mx-3 p-4 student-app-heading">
          <div class="d-flex align-items-center">
            <img :src="require('/src/assets/images/d-board/association-o.svg')" alt="" srcset="">
            <h4 class="ms-3">
              Associations Joined
            </h4>
          </div>
        </div>
        <div class="student-app px-4 mb-3 mx-auto position-relative" style="overflow-x:auto;">
            <AssociationTile  v-for="(association, index) in associationsIn" @leave="leaveAssociation($event, index)" :key="index" :association="association" />
        </div>
        <div class="mx-3 p-3 student-app-heading">
          <div class="d-flex align-items-center">
            <img :src="require('/src/assets/images/d-board/association-o.svg')" alt="" srcset="">
            <h4 class="ms-3">
              Associations Available
            </h4>            
          </div>
        </div>
        <div>
            <div class="col-md-4 px-4">
                <input type="text" class="form-control" placeholder="search" >
            </div>
        </div>
        <div class="student-app px-4 mb-5 mx-auto position-relative" style="overflow-x:auto;">
                <AssociationTile v-for="(association, index) in associations.data" :key="index" @join="joinAssociation($event, index)" :association="association" :request="true" :full="full"/>
        </div>
        <PaginateData :paginationData="associations" @onFetch="nextPage" :filters="filters" method="post" />
    </div>
</template>

<script>

import AssociationTile from '@/components/AssociationTile.vue';
import PaginateData from '@/components/Global/Pagination.vue';
// import FilterOptions from '@/components/Global/FilterOptions.vue';
 import { post, get } from '@/api/client';

export default {
    name:'AssociationsView',
    components: {  AssociationTile, PaginateData },
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
            selectedStatus: null,
            associations: [],
            associationsIn: [],
        };
    },
    async created() {        
        this.fetchAssociations();
        let resp = await get(this.$endpoints.getJoinedAssociations+'/'+this.userInfo.high_school.id);            
            if(resp?.message){
                this.associationsIn = resp.message
        }
    },
    methods: {
        leaveAssociation(data, index){           
            this.associationsIn.splice(index,1)
            if (this.associations && this.associations.data) {
                data.isFollow = 0;
                this.associations.data.unshift(data);
            } else {
                this.associations.data= [data];
                console.error('Associations or associations.data is undefined.');
            } 
                      
        },
        joinAssociation(data, index){            
            this.associations.data.splice(index,1)
            data.isFollow = 1;
            this.associationsIn.unshift(data);
        },
        async fetchAssociations() {
            let resp = await post(this.$endpoints.getAssociations,{});            
            if(resp?.message){
                this.associations = resp.message
            }
        },
        nextPage(data) {
            this.associations = data;
        },
    },
    computed:{
        full(){
            return this.associationsIn.length == 2 ?  true : false
        }
    }
};
</script>

<style lang="scss" scoped>
$primary: #fac515;
$warning: #fdb022;
$scrollbar-thumb-bg: #dc6803;
$scrollbar-color: #f79009;
$gray: #d0d5dd;
$text-color: #1d2939;

::v-deep(#inputStatus.p-dropdown) {
    .p-dropdown-items {
        background-color: red !important;
        border-bottom: 1px solid #fac515;
    }

    .p-dropdown-panel .p-dropdown-items .p-highlight {
        color: #495057;
        background: #f70909;
    }

    // .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    //   color: #5de40f;
    //   background: #e70c30;
    // }
}

/** input field hack to ensure the clear btn within the input fields are always circular **/
.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0px;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
}

.trackform-input {
    input {
        padding-right: 40px;
    }

    .form-btna {
        position: absolute;
        right: 10px;
        z-index: 5;
        padding: 4px;
        border: 1px solid #98a2b3;
        border-radius: 100%;
        font-size: 10px;
        background-color: #98a2b3;
        color: white;

        &:hover {
            background-color: #c0c8d4;
        }
    }
}

::v-deep(#inputStatus.p-dropdown-panel) {
    .p-dropdown-items {
        background-color: red !important;
        border-bottom: 1px solid #fac515;
    }

    .p-dropdown-items .p-highlight {
        color: #495057;
        background: #f70909 !important;
    }
}

.active {
    background-color: $primary !important;
}

.search-btn {
    background-color: $gray;
    padding: 8px 24px;
}

.search-btn {
    font-size: 14px !important;
}

.filter-btn {
    background-color: $gray;
}

.text-xs {
    font-size: 0.85rem !important;
}


.student-app-heading {
  margin-top: 25px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
  background-color: #fff;

  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0;
    line-height: 38px;
    color: $text-color;
  }
}
</style>