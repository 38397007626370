<template>
  <div>
    <div    
      class="row d-flex shadow px-1 py-3 my-4"
      style="white-space: pre; background-color: #fff; min-width:1000px;"
    >
      <div class="col-12 d-flex align-items-center">
        <div class="col d-flex justify-content-between align-items-center">
          <div class="col-1">
            <img
              style="width:50px;height:50px; border-radius: 50px;"
              :src="
                student?.document?.formal_photo? student?.document?.formal_photo : require('/src/assets/images/d-board/avater-1.svg')
              "
              alt=""
            />
          </div>
          <div class="col-9">
            <div class="container">
              <div class="row">
                <div class="text-secondary track-cell col-3 px-1">
                  <span>Full Name</span>
                  <p class="text-dark mb-0 text-truncate">
                    {{ student?.full_name }}
                  </p>
                </div>
               <!--  <div class="text-secondary track-cell col-4 px-1">
                  <span>Email </span>
                  <p class="text-dark mb-0 text-truncate">
                    {{ student?.email }}
                  </p>
                </div> -->
                <div class="text-secondary track-cell col-3 px-1">
                  <span>Country </span>
                  <p class="text-dark mb-0 text-truncate">
                    {{ student?.country }}
                  </p>
                </div>
                <div class="text-secondary track-cell col-3 px-1">
                  <span>National ID</span>
                  <p class="text-dark mb-0 text-truncate">
                    {{ student?.national_id }}
                  </p>
                </div>
                <div class="text-secondary track-cell col-3 px-1">
                  <span>Status </span>
                  <p class="text-dark mb-0 d-flex align-items-center">
                    <span style="margin-right: 5px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        border-radius="5px"
                        class="bi bi-circle"
                        :style="{
                          backgroundColor: getTextColor(student?.tracking_status),
                          fill: getTextColor(student?.tracking_status),
                          borderRadius: '5px',
                        }"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                      </svg>
                    </span>
                    <span class="text-truncate" :style="'color:'+getTextColor(student?.tracking_status)">
                      {{ student?.tracking_status ??'N/A' }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2" style="text-align: end;">
            <button
              @click="showDetails()"
              class="view-track btn text-dark px-3 py-2 ripple"
              :class="trackDetails ? 'primary-btn' : 'btn-disabled'"
            >
              View Track
            </button>
          </div>
        </div>
      </div>
    </div>
    <Transition name="slide-fade">
      <div
        v-if="trackDetails"
        class="tracking-container row no-gutters pb-3 px-5 px-0 mb-5"
      >
        <div v-if="student?.programs?.length > 0" class="track-details">
          <div
            v-for="(details, i) of student?.programs"
            :key="i"
            class="shadow rounded"
            id="cons"
            style="cursor: grab; overflow: auto"
          >
            <div class="card">
              <div class="card-body">
                <h6 class="card-title text-dark px-2 py-3 row mb-0">
                  <span class="text-secondary col-4 text-truncate p-0"
                    >University</span
                  >
                  <span class="col text-truncate p-0">{{
                    details.university
                  }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-3 row mb-0">
                  <span class="text-secondary col-4 text-truncate p-0"
                    >Major</span
                  >
                  <span class="col text-truncate p-0">{{ details?.major }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-3 row mb-0">
                  <span class="text-secondary col-4 text-truncate p-0"
                    >Country</span
                  >
                  <span class="col text-truncate p-0">{{
                    details?.country
                  }}</span>
                </h6>
              </div>
              <h6 class="card-header text-bold">Application Status</h6>
              <div class="card-body">
                <div class="row d-flex flex-row py-3">
                  <div class="col-3">
                    <div class="mx-auto">
                      <svg class="svg-icon" style="width: 40px; height: 40px; vertical-align: middle; fill: currentColor; overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="512" cy="512" r="512" fill="#ffb30022" />
                            <g fill="#ffb300" transform="translate(512, 512) scale(0.5) translate(-512, -512)">
                              <path d="M895.8 762.7v11.1c0.1-1.8 0.2-3.7 0.2-5.6 0-1.8 0-3.7-0.2-5.5z"  />
                              <path d="M481.4 513L116.5 339.7c-26.9-12.8-26.3-51.3 0.9-64.3l364.1-172.8c19.4-9.2 41.8-9.3 60.9-0.2l365 172.7c26.9 12.7 26.4 51.3-0.8 64.3L542.5 512.8c-19.4 9.2-41.9 9.3-61.1 0.2z"  />
                              <path d="M906.6 543.6L542.5 717.1c-19.4 9.3-41.9 9.4-61.1 0.2L116.5 544c-26.9-12.8-26.3-51.3 0.9-64.3l72.4-34.4L454 570.8c17.9 8.5 37.8 13 57.6 13 20.1 0 40.3-4.6 58.5-13.2l264.2-125.9 73.2 34.6c26.9 12.7 26.4 51.3-0.9 64.3z"  />
                              <path d="M906.6 747.9L542.5 921.4c-19.4 9.3-41.9 9.4-61.1 0.2L116.5 748.3c-26.9-12.8-26.3-51.3 0.9-64.3l72.4-34.4L454 775.1c17.9 8.5 37.8 13 57.6 13 20.1 0 40.3-4.6 58.5-13.3L834.2 649l73.2 34.7c27 12.6 26.5 51.2-0.8 64.2z"  />
                            </g>
                          </svg>
                    </div>
                  </div>
                  <div class="col-9" style="padding-left: 10px;color:#ffb300;">
                    <h6 class="m-0" style="color:inherit" >Submitted</h6>
                    <p class="m-0  text-xs" style="color:inherit">
                      {{ details?.submitted_date }}
                    </p>
                  </div>
                </div>
                <div class="row d-flex align-items-center flex-row py-3">
                  <div class="col-3">
                    <div class="mx-auto">
                      <svg width="40" height="40" viewBox="0 0 40 40" :fill="getTextColor( getMiddleStatus(details?.is_accepted))" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" :fill="getTextColor( getMiddleStatus(details?.is_accepted))+'22'"/>
                        <path  :fill="getTextColor( getMiddleStatus(details?.is_accepted))" d="M14.4484 12.846L28.2679 19.6554C28.3433 19.6926 28.4067 19.7501 28.4511 19.8214C28.4955 19.8927 28.519 19.9751 28.519 20.0591C28.519 20.1431 28.4955 20.2254 28.4511 20.2968C28.4067 20.3681 28.3433 20.4256 28.2679 20.4627L14.4475 27.2721C14.3701 27.3102 14.2834 27.3252 14.1977 27.3153C14.112 27.3054 14.0309 27.2712 13.9641 27.2165C13.8974 27.1619 13.8477 27.0892 13.8211 27.0072C13.7945 26.9251 13.792 26.8372 13.8139 26.7537L15.1828 21.5832C15.205 21.4996 15.2509 21.4241 15.3149 21.3658C15.3789 21.3076 15.4583 21.269 15.5437 21.2547L21.7366 20.2224C21.7735 20.2163 21.8083 20.2011 21.8379 20.1782C21.8674 20.1552 21.8908 20.1253 21.9058 20.091L21.922 20.037C21.9304 19.987 21.9217 19.9356 21.8971 19.8911C21.8726 19.8467 21.8338 19.8118 21.787 19.7922L21.7366 19.7778L15.5095 18.7401C15.4243 18.7257 15.345 18.6871 15.2812 18.6288C15.2174 18.5706 15.1716 18.4952 15.1495 18.4116L13.8139 13.3653C13.7917 13.2819 13.7941 13.1938 13.8206 13.1116C13.8471 13.0293 13.8968 12.9565 13.9636 12.9018C14.0304 12.847 14.1115 12.8126 14.1974 12.8028C14.2832 12.7929 14.37 12.8079 14.4475 12.846H14.4484Z"/>
                      </svg>
                    </div>
                  </div>
                  <div class="col-9" style="padding-left: 10px">
                    <h6 class="m-0"  :style="'color:'+getTextColor( getMiddleStatus(details?.is_accepted)) +' !important'">Accepted/Rejected</h6>
                    <p class="m-0 text-secondary text-xs" :style="'color:'+getTextColor( getMiddleStatus(details?.is_accepted)) +' !important'">
                      {{ getMiddleStatus(details?.is_accepted)}}
                    </p>
                  </div>
                </div>
                <div class="row d-flex flex-row py-3">
                  <div class="col-3 ">
                    <div class="mx-auto">
                      <svg width="40" height="40" viewBox="0 0 40 40" :fill="getTextColor(getFinalStatus(details.is_accepted))" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" :fill="getTextColor(getFinalStatus(details.is_accepted))+'22'"/>
                        <path :fill="getFinalStatus(details.is_accepted)" d="M20.5303 20.9899C17.7063 20.9899 15.307 21.8111 13.7399 23.0983V25.4923H27.3217V23.0983C25.7546 21.8111 23.3548 20.9899 20.5303 20.9899V20.9899ZM33 19.7613L20.4974 14L8 19.7613L10.0832 20.7147V22.181C9.56087 22.2891 9.5593 22.5628 9.5593 22.8883C9.5593 23.0805 9.62303 23.3057 9.74422 23.4404L9.02961 24.97H11.5788L10.8705 23.4394C10.9896 23.3046 11.0873 23.1061 11.0873 22.9139C11.0873 22.5889 10.6056 22.2886 10.6056 22.1805V20.9597L13.2175 22.1329V22.029C14.7847 20.6661 17.5381 19.801 20.5298 19.801C23.5225 19.801 26.277 20.6661 27.8441 22.029V22.1329L33 19.7613V19.7613Z"/>
                      </svg>
                    </div>

                  </div>
                  <div class="col-9" style="padding-left: 10px">
                    <h6 class="m-0" :style="'color:'+getTextColor(getFinalStatus(details.is_accepted))+' !important'">Accepted University</h6>
                    <p class="m-0 text-secondary text-xs" :style="'color:'+getTextColor(getFinalStatus(details.is_accepted))+' !important'">
                      {{ getFinalStatus(details.is_accepted) != 'Not Available'?getFinalStatus(details.is_accepted):"Not Enrolled" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-details shadow d-flex flex-col mx-auto">
          <h2>No applications yet</h2>
          <p>This user is still finding their desired university</p>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  name: 'StudentTrack',
  props: ['student'],
  data() {
    return {
      trackDetails: false,
    };
  },
  methods: {
    showDetails() {
      // console.log(this.trackDetails);
      this.trackDetails = !this.trackDetails;
      
    },
    getIconColor(status) {
      try{

        return status.toLowerCase() == 'applying'
        ? '.tapplying'
        : status.toLowerCase() === 'got admission'
        ? '.taccepted'
        : status.toLowerCase() === 'accepted'
        ?'':
        '.trejected';      
      }catch(e){
        //console.log(e)
      }
      return ''
    },
    getFinalStatus(status){
      return status == 'Enrolled'? status:'Not Available'
    },
    getMiddleStatus(status){
      return status =='Enrolled'? 
          'Accepted': 
          status =='Accepted'?
          'Accepted':
          status =='Rejected'?
          'Rejected':
          'Not Availalbe'
    },
    getTextColor(status) {
      try{        
          return status.toLowerCase() == 'in review' ||status == ''
              ? '#ffb300'
              : status.toLowerCase() === 'enrolled'
              ? '#198754'
              : status.toLowerCase() === 'accepted'
              ?'#009aff'
              : status.toLowerCase() === 'rejected'?
              "#DC3545":'#555555';      
        }catch(e){
          //console.log(e, status)
        }
        return '';
      },
  },
  computed: {
   
    },
};
</script>

<style lang="scss" scoped>
$primary: #fac515;
$warning: #fdb022;
$scrollbar-thumb-bg: #dc6803;
$scrollbar-color: #f79009;
$gray: #d0d5dd;



.track-cell {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px!important;
  p{
    font-size: 14px!important;
  }
}
.tracking-container {
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 7px;
    border-bottom: $scrollbar-thumb-bg;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    //background: $scrollbar-color;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //     background: $scrollbar-thumb-bg;
  // }

  h3 {
    margin: 3rem 0rem 0rem;
  }

  .track-details {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 0 3rem;
    //padding-bottom: 3rem;
    max-width: calc(100% - 3px);

    & > div {
      display: flex;
      flex-direction: column;
      margin: 0rem 1rem 0rem 0rem;
      min-width: 350px;

      div {
        flex-direction: column;
        //padding: 1rem;
        //min-height: 181px;
        display: flex;
      }

      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 1rem;
      }
    }
  }
  .no-details {
    flex-direction: column;
    padding: 1.5rem;
    align-items: center;

    h2 {
      color: #93370d;
      font-size: 35px;
      line-height: 44px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .card {
    .card-header {
      padding: 1rem;
      background-color: inherit;
      border-bottom-color: $warning;
      border-bottom-style: solid;
      border-top-color: $warning;
      border-width: 1px;
      border-top-style: solid;
    }
  }
}

.active,
.view-track:active {
  background-color: $primary !important;
}

.view-track {
  font-size: 13px !important;
  //transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0 $primary !important;
  -webkit-transition: ease-out 0.4s !important;
  -moz-transition: ease-out 0.4s !important;
  transition: ease-out 0.4s !important;

  &:hover {
    // background-color: $primary !important;
    color: rgb(245, 245, 245) !important;
    //transition: color 0.5s;
    box-shadow: inset 150px 0 0 0 $primary !important;
    cursor: pointer;
  }
}

.text-xs {
  font-size: 0.85rem !important;
}

/** Styling for transistion**/
// .v-enter-active,
// .v-leave-active {
//   transition: opacity 0.5s ease;
// }

// .v-enter-from,
// .v-leave-to {
//   opacity: 0;
// }

.slide-fade-enter-active {
  transition: all 0.3s linear;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(0px);
  opacity: 0;
}

.button_slide {
  color: #000;
  // background-color: #93370d;
  //border: 2px solid rgb(216, 2, 134);
  //border-radius: 0px;
  //padding: 18px 36px;
  //display: inline-block;
  //font-family: 'Lucida Console', Monaco, monospace;
  //font-size: 14px;
  //letter-spacing: 1px;
  // cursor: pointer;
  box-shadow: inset 0 0 0 0 #d80286;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  &:hover {
    box-shadow: inset 600px 0 0 0 #d80286;
  }
}
.text-primary1{
  color:rgb(0,123,255) !important;
}
</style>