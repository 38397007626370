import { defineStore } from "pinia";
import Swal from 'sweetalert2'
import {  
  removeToken,
} from "@/utilities/authTokens";

// import { fetchWrapper } from '@/helpers';
// import { router } from '@/router';
//import { useUserStore } from "./user";
import { endpointRoutes } from "./endpointRoutes";
import { post, get } from "@/api/client"

// import { useAlertStore } from '@/stores/alert';

// const baseUrl = `${import.meta.env.VITE_API_URL}/users`;

export const useAuthStore = defineStore("auth", {
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    userId:0,
    password: '',    
    userToken: null,
  }),
  getters: {
  },
  persist: true,
  actions: {
    setStoreToken(token) {
            this.userToken = token
    },
    async login(unique_id, password) {
      //const Ep = eRoute();
      //this.$store.endpointRoutes.login;
      try {        
        const AuthenticatedUser = await post(endpointRoutes.login, {
          unique_id: unique_id,
          password: password,
        });        
        if(AuthenticatedUser !== false){
          alert('Your OTP: '+AuthenticatedUser?.otp)
        }
        if (AuthenticatedUser.status !== "success") {
          //alert(AuthenticatedUser.message);
          return false
        }        
        
        localStorage.setItem('userInfo', JSON.stringify(AuthenticatedUser?.user))
        localStorage.setItem('schoolInfo', JSON.stringify(AuthenticatedUser?.school))
        this.$state.password = AuthenticatedUser.password
        this.$state.userId = unique_id
        return true  
        /* 
        setToken(AuthenticatedUser.password);
        //update user in pinia state with result from server
        //this.setStoreToken()
        // store user token in local storage to keep user logged in between page refreshes

        // initialize user store and  update its state
        const userStore = useUserStore();
        userStore.setUser(AuthenticatedUser.user);
        

        if(AuthenticatedUser.status === 401) alert('expired token')

        // redirect to previous url or default to home page
        //return router.push("/dashboard");
        */
       // return (window.location.href = "/dashboard");
      } catch (error) {
        // const alertStore = useAlertStore();
        // alertStore.error(error);
        console.log(error);
        Swal.fire(error?.response?.message ?? 'something went wrong');
        return false
      }
    //   this.state.userToken = 1;

    },
    async logout() {

        //set usertoken in pinia state to null
        try {
          /* window.modalOpened = true
          setTimeout(()=>window.modalOpened = false, 5000) */
        get("high_school/logout_hs");        
        this.userToken = null;      
        this.$state.userToken = null
        removeToken()        
      }catch(err){
        //alert(err)
        console.log(err)
      }

      //   if(logoutRequest.status == 401) alert('expired token')

      //remove token for local storage
      removeToken();

      //redirect to login page
     // return (window.location.href = "/login");
    },
  },
});
