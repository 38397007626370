<template>
    <div>

        <div id="appM">
            <RequestLoader />
            <router-view />        
        </div>
        
        
    </div>
</template>
<script>
    import RequestLoader from '@/components/Global/RequestLoader';
    export default {
        components: { RequestLoader },
        mounted(){           
        
        }
        };
</script>