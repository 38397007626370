<template>
  <div>
  <v-container>
    <div class="search-container d-flex">
      <div class="inbox-count py-2">
        <span
          ><img :src="require(`/src/assets/images/create_icon_wh.png`)" alt=""
        /></span>
        <span>Create New Message</span>
      </div>
    </div>
    <div class="form pt-5">
      <div class="">
        <div class="">
          <div class="row g-3 position-relative">
            <div class="col-md-10 mb-2 mx-auto">
              <label for="emailTo" class="form-label">Email To</label>
              <v-multiselect v-model="selectedEmails" :options="emails" filter optionLabel="receiver_email" placeholder="Select Emails"
            :maxSelectedLabels="3" class="w-100" />             
            </div>
            <br>
            <div class="col-md-10 mb-2 mx-auto">
              <v-editor
                    v-model="message"
                    editorStyle="height: 250px; width:100%"                  
                  />                                        
            </div>
            <!-- <div class="col-md-9 py-2 mx-auto">
              <input class="" type="file" id="upload_file" multiple />
            </div> -->

            <div class="col-md-10 py-2 mx-auto">
              <label for="file-upload" class="custom-file-upload">
                <span class="file-btn">
                  <span v-if="someFiles.length > 0">
                    <img
                      :src="
                        require(`@/assets/images/attachment_icon.svg`)
                      " /></span
                  >Attach File</span>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/png, image/jpeg, image/gif, .pdf"
                  @change="handleFileChange"
                />
                <span class="mx-2">No File Chosen</span>
              </label>
            </div>
            <!-- {{someFiles}} -->

            <div
              class="col-md-10 py-2 mx-auto"
              style="
                gap: 5px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
              "
              v-if="someFiles"
            >
              <div
                v-for="file in someFiles"
                :key="file"
                style="
                  position: relative;
                  cursor: pointer;
                  border: solid 1px #efefef;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  padding: 0.8em;
                  width: 150px;
                  background-color: #fff;
                  gap: 10px;
                "
              >
                <div id="doc-actions">
                  <div>
                    <button class="btn btn-info">view</button>
                  </div>
                  <div>
                    <button>delete</button>
                  </div>
                </div>
                <div>
                  <img :src="require(`@/assets/images/pdf_icon.png`)" />
                </div>
                <div style="font-size: 12px">
                  <div
                    style="
                      width: 90px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ file.name }}
                  </div>
                  <div style="font-size: 10px">{{ (file.size/1024/1024).toFixed(2) }} MB</div>
                </div>
              </div>
            </div>

            <div class="col-md-10 py-2 mx-auto">
              <p style="font-size: 10px!important">
                You can only upload files up to 10 MiB in size each from the
                following types: JPEG, GIF, PNG, PDF. Please make sure that the
                image is not blurry or distorted and has plenty of contrast.
                Remember that they should be legible when printed out. The
                documents must be upright (not upside down) and in portrait mode
                (taller than wider). Other common office file formats (Word,
                Excel, PowerPoint) are supported as well.
              </p>
            </div>

            <div class="col-md-10 d-flex justify-content-end py-2 mx-auto">
              <button
                class="btn primary-btn col-md-4"
                @click="send"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
 <!--  <transition name="fade" >  
    <v-custommodal v-if="showModal" @submit="send" @cancel="cancel" :message="modalMessage" />  
  </transition> -->
  </div>
</template>

<script>
import AttachFile from '@/components/Global/AttachFile.vue'
import {showModal, showToast} from '@/plugins/modal'
import { useChatsStore } from "@/stores/chats";
import {postFormData,post} from '@/api/client';
export default {
  name: "ComposeMail",
  components: [AttachFile],
  data() {
    return {
      modalMessage:"",
      showModal:false,
      message:'',
      someFiles: [],
      chatStore: useChatsStore(),
      selectedEmails:[],
      attchments:[],
      emails:[],
    };
  },
  methods: {
    cancel(){      
      this.showModal = false
    },
    sendMessage(){
       /*  this.modalMessage ="Are you sure you want to send"
        this.showModal = true 
        */
    },
    async send(){
      
     const confirm = await showModal({
        text:"Are you sure you want to send",
        confirmText:'Yes',
        cancelText:'No'
      })
      if(!confirm){
        return false
      }
        const res = await post(this.$endpoints.sendMessage,{
            receivers: this.selectedEmails.map((item)=>{
              return {
                receiver_id: item.receiver_id,
                user_type: item.user_type
              }
            }),        
            message:this.message,
            attachments:this.attachments
          })
          if(res){
            showToast(res.message)            
          }
    

      
    },
    async handleFileChange(e) {
      //this.$emit('input', e.target.files[0])
      if (e.target.files[0].size > 1097152) {
        showModal({text:"File is too big!"});
        return;
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);        
      formData.append('folder_name', "Programs");        
      const res = await postFormData(
          "api/file_upload",
          formData,
        );                     
      this.attchments.push(res);
      this.someFiles.push(e.target.files[0]);      
    },


  },
  async mounted(){
      setTimeout(() => {        
        
        this.emails = this.chatStore.chats.map((item)=>{
          return {
            receiver_id: item.sender_id,
            receiver_email: item.sender_email,
            user_type: item.user_type,
          }         
        })
        if(this.chatStore.selected !== null){          
            this.selectedEmails.push({
              receiver_id: this.chatStore.selected.sender_id,
              receiver_email: this.chatStore.selected.sender_email,
              user_type: this.chatStore.selected.user_type,
            })
            this.chatStore.setSelected(null)
          }
      }, 1000);
    //  const response = await get(this.$endpoints.chats_index)

      //this.messages = response?.chats_index
      ///console.log(this.messages)
    },
};
</script>


<style lang="scss">
#upload_file {
  width: 100%;
  padding: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.custom-file-upload {
  display: flex !important;
  align-items: center;
  border: 1px solid #ced4da;
  display: inline-block;
  padding: 3px;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  .file-btn {
    background-color: #fac515;
    padding: 0.5rem 1.3rem;
    border-radius: 7px;
    font-size: 10px;
    display: flex;
    gap: 10px;
  }
}
#file-upload {
  display: none;
}

#doc-actions {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: #d0d5dd;
  justify-content: space-around;
  align-items: center;
  visibility: hidden;
  z-index: 100;
}

#doc-actions:hover {
  visibility: visible;
}

</style>
