// import Vue from 'vue'
// import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";
import IndexView from "../views/IndexVue";
import Inbox from "../views/Inbox";
import SignIn from "../views/signIn/SignIn";
import EmailOTP from "../views/otp/EmailOTP";
import Settings from "../views/settings/Settings";
import HelpAndSupport from "../views/HelpAndSupport";
import Dashboard from "../views/dashboard/Dashboard";
import TermsPrivacy from "../views/terms/Terms-privacy";
import ResetPassword from "../views/password/ResetPassword";
import Notification from "../views/notification/Notification";
import ForgetPassword from "../views/password/ForgetPassword";
import TechnicalSupport from "../views/terms/Technical-Support";
import StudentTracking from "@/views/tracking/StudentTracking.vue";
import StudentApplication from "@/views/student/StudentApplication.vue";
import StudentSidebar from "../views/student/StudentSidebar.vue";
import ConfirmPasswordReset from "../views/password/ConfirmPasswordReset";
import ComposeMail from "@/components/Mail/ComposeMail.vue";
import ViewMail from "@/components/Mail/ViewMail.vue";
import MailInbox from "@/components/Mail/MailInbox.vue";
import SentMail from "@/components/Mail/SentMail.vue";
import middleware from "@/middleware/auth.js";
import Chat from "../views/chats/ChatBox.vue";
import viewChats from "../views/chats/ChatDetails/viewChats.vue";
import FavouriteMessage from "../views/chats/FavouriteMessage.vue";
import UnreadMessage from "../views/chats/UnreadMessage.vue";
import Earnings from "../views/earnings/earnings.vue";
import Associations from "../views/association/associations.vue";
// Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "IndexView",
    component: IndexView,
    // meta: { requiresAuth: true },
    children: [
      //#---chats
  {
    path: "/chats/view",
    name: "ChatDetails",
    component: viewChats,
    props:true,
  },
  {
    path: "/chats/",
    name: "Chat",
    component: Chat,
  },     
  {
    path: "/chats#favourites",
    name: "Favouritemessage",
    component: FavouriteMessage,
    props: true, 
  },
  {
    path: "/chatsunread",
    name: "UnreadMessage",
    component: UnreadMessage,
    props: true, 
  },  
  {
    path: "/chats#archived",
    name: "Archived",
    component: UnreadMessage,
    props: true, 
  },
  //#ends chats
      {
        path: "",
        name: "Home",
        redirect: "/dashboard",
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/help-and-support",
        name: "HelpAndSupport",
        component: HelpAndSupport,
      },
      {
        path: "/inbox",
        name: "Inbox",
        component: Inbox,
        children: [
          {
            path: "",
            name: "MailInbox",
            component: MailInbox,
          },
          {
            path: ":id",
            name: "viewMail",
            component: ViewMail,
          },
          {
            path: "create",
            name: "Create",
            component: ComposeMail,
          },
          {
            path: "sent",
            name: "Sent",
            component: SentMail,
          },
          {
            path: "draft",
            name: "Draft",
            component: SentMail,
          },
          {
            path: "trash",
            component: SentMail,
          },
        ],
      },
      {
        path: "/student-application",
        name: "StudentApplication",
        component: StudentApplication,
      },
      {
        path: "/student-application/:id/profile",
        name: "StudentSidebar",
        component: StudentSidebar,
      },

      {
        path: "/student-tracking",
        name: "StudentTracking",
        component: StudentTracking,
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notification,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
       {
        path: "/earnings",
        name: "Earnings",
        component: Earnings,
      },
       {
        path: "/associations",
        name: "Associations",
        component: Associations,
      },
    ],
  },
  {
    path: "/email-verification",
    name: "EmailOTP",
    component: EmailOTP,
    meta: { skipAuth: true },
  },
  {
    path: "/forgot-password",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: { skipAuth: true },
  },
  {
    path: "/reset-password/:email",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { skipAuth: true },
  },
  {
    path: "/confirm-reset-password/:email",
    name: "ConfirmPasswordReset",
    component: ConfirmPasswordReset,
    meta: { skipAuth: true },
  },
  {
    path: "/terms-privacy",
    name: "TermsPrivacy",
    component: TermsPrivacy,
    meta: { skipAuth: true },
  },
  {
    path: "/technical-support",
    name: "TechnicalSupport",
    component: TechnicalSupport,
    meta: { skipAuth: true },
  },
  
  {
    path: "/login",
    name: "LogIn",
    component: SignIn,
    meta: { skipAuth: true },
  },
];

// const isAthenticated = localStorage.getItem("user");
const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top btw routes
    return { top: 0 };
  },
});
router.beforeEach(middleware);

export default router;
