<template lang="html">
  <div style="min-height: 91vh;">
    <h3 class="mx-5 fw-bold">Dashboard</h3>
    <hr style="background: #f79009" />
    <div class="mx-4 mt-5">
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-xl-3 mb-3 bg-white"
          v-for="docCount in docFileCount"
          :key="docCount.id"
        >
          <div :class="docCount.style">
            <div class="fw-bold d-flex justify-content-between align-self-end">
              <h1>{{ docCount.count }}</h1>
              <div class="d-flex align-items-start">
                <img
                  :src="require(`/src/assets/images/d-board/${docCount.img}`)"
                  alt=""
                />
              </div>
            </div>
            <h5>{{ docCount.title }}</h5>
            <small>{{ docCount.excerpt }}</small>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-5" />
    <div class="mx-3 p-3 student-app-heading">
      <div class="d-flex justify-content-between align-items-center">
        <h4>
          <img
            class="px-2"
            :src="
              require('/src/assets/images/d-board/student-application-black.svg')
            "
            alt=""
            height="24"
          />
          Student Applications
        </h4>
        <button
          class="btn btn-large bg-yellow px-3 fw-500 ripple h-100"
          @click="() => this.$router.push('/student-application')"
        >
          View All
        </button>
      </div>
    </div>
    <div class="student-app px-3">
      <SkeletalLoader v-show="sLoading" :col="12" :row="5"/>
      <div class="table-responsive px-4" tabIndex="1">
        <div v-for="(student, index) in students?.data?.slice(0,3)" :key="index">
          <StudentApplication
            :student="student"
            :index="index"
            @onClick="selectStudent(student)"
            :isDashboard="true"
          />
          <div v-if="index < 4"></div>
        </div>
      </div>
    </div>

    <div class="student-track mx-3 p-3">
      <div class="d-flex justify-content-between align-items-center">
        <h4>
          <img
            class="px-2"
            :src="
              require('/src/assets/images/d-board/student-tracking-black.svg')
            "
            alt=""
            height="24"
          />
          Student Tracking
        </h4>
        <button
          class="btn btn-large bg-yellow px-3 fw-500 ripple h-100"
          @click="() => this.$router.push('/student-tracking')"
        >
          View All
        </button>
      </div>
    </div>

    <div class="student-app mx-3">
      <div class="table-responsive px-4" tabIndex="1">
        <SkeletalLoader v-show="pLoading" :col="12" :row="5"/>
        <div class="w-100">          
          <StudentTrack v-for="(studentApp, index) in students_program_applications?.data?.slice(0,3)" :key="index" :student="studentApp" />                              
        </div>
      </div>
    </div>

    <div class="row p-3">
      <div class="col-lg-12 mt-3 mb-4 mr-4">
      <!--   <div
          class="shadow p-3 d-flex justify-content-between align-items-center bg-white"
        >
          <h4 class="d-flex mb-0 align-items-center p-header">
            <img
              class="px-2"
              :src="require('/src/assets/images/d-board/inbox-black.svg')"
              alt=""
              height="24"
            />Inbox
          </h4>
          <button
            class="btn btn-large bg-yellow px-3 fw-500 ripple h-100"
            @click="() => this.$router.push('/inbox')"
          >
            View All
          </button>
        </div>
        <hr class="mt-5 grey" />
        <div v-for="(message, index) in mailSummary" :key="index">
          <div class="d-flex justify-content-between align-items-center">
            <span class="me-2 align-self-stretch position-relative">              
              <img :src="message.imgURL" alt="" class="inboxImg" />
              <span
                class="badge position-absolute translate-custom-middle p-02 bg-orange rounded-pill"
              >
                <span class="visually-hidden">unread messages</span>
              </span>
            </span>
            <div class="me-2">
              <span>{{ message.name }}</span>
              <p class="text-dark font-xm m-0" v-if="message.email.length > 16">
                {{ message.email.slice(0, 16) }}..
              </p>
              <p class="text-dark font-xm m-0" v-else>{{ message.email }}</p>
            </div>
            <div class="me-3">
              <p class="text-dark font-xm mt-1 m-0">{{ message.body }}</p>
            </div>
            <p class="fs-6 mb-0 align-self-stretch">1m</p>
          </div>
          <hr class="grey" />
          <hr class="grey" v-if="index != inbox.length - 1" />
        </div>
      </div>
      <div class="col-lg-6 mt-3 mb-4"> -->
        <div class="shadow p-3 bg-white">
          <h4 class="p-header">
            <img
              class="px-2"
              :src="
                require('/src/assets/images/d-board/help-support-black.svg')
              "
              alt=""
              height="24"
            />Help Guide
          </h4>
        </div>
        <div class="help-videos-container position-relative">
        <div class="help-videos mb-3">
          <div v-for="(video, index) in videos" :key="index" class="d-flex align-items-center flex-column position-relative">
            <div
              class="videoContainer help-video"
              v-if="isVideoUrl(video.link)"
            >
              <video
                :src="video.link"
                width="250"
                height="130"
                controls
              ></video>
            </div>
            <div
              class="videoContainer help-video"
              v-else-if="isYouTubeUrl(video.link)"
            >
              <iframe
                width="250"
                height="130"
                :src="embedYouTubeUrl(video.link)"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <div
              class="videoContainer help-video"
              v-else-if="isImageUrl(video.link)"
            >
              <img :src="video.link" width="250" height="130" />
            </div>
            <h3>{{ video.title }}</h3>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentApplication from "@/components/StudentApplication/StudentApplication.vue";
import StudentTrack from '@/components/StudentsTracking/StudentTrack.vue';
import { get } from "@/api/client";
import SkeletalLoader from "@/components/Global/SkeletalLoader.vue";
export default {
  name: "UserDashboard",
  components: { StudentApplication, StudentTrack,SkeletalLoader },
  data() {
    return {
      docFileCount: [
        {
          id: 1,
          style: "border border-primary px-lg-3 p-3 py-4 rounded border-2",
          count: "-",
          img: "arrow-1.svg",
          title: "Total Applications",
          excerpt: "Number of students documents for evaluation.",
        },
        {
          id: 2,
          style: "border border-warning px-lg-3 p-3 py-4 rounded border-2",
          count: "-",
          img: "arrow-2.svg",
          title: "In Review",
          excerpt: "Number of students documents for evaluation.",
        },
        {
          id: 3,
          style: "border border-success px-lg-3 p-3 py-4 rounded  border-2",
          count: "-",
          img: "arrow-3.svg",
          title: "Approved",
          excerpt: "Number of students documents for evaluation.",
        },
        {
          id: 4,
          style: "border border-danger px-lg-3 p-3 py-4 rounded border-2",
          count: "-",
          img: "arrow-4.svg",
          title: "Rejected",
          excerpt: "Number of students documents for evaluation.",
        },
      ],
      students: [],
      inbox: [],
      students_program_applications: [],
      videos: [],
      sLoading:true,
      pLoading:true,  
    };
  },
  methods: {
    getTextColor(status) {
      try{        
          return status.toLowerCase() == 'in review' ||status == ''
              ? '#ffb300'
              : status.toLowerCase() === 'enrolled'
              ? '#198754'
              : status.toLowerCase() === 'accepted'
              ?'#009aff'
              : status.toLowerCase() === 'rejected'?
              "#DC3545":'#555555';      
        }catch(e){
          //console.log(e, status)
        }
        return '';
      },
    convertYouTubeLinksToEmbedded(text) {
            const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w\\-]+)(?:\S+)?/g;
            return text.replace(youtubeRegex, (match, videoId) => {
                return `<div style="display: flex; justify-content: center;"><iframe width="260" height="155" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe></div>`;
            });
        },
        isYouTubeUrl(url) {
            const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
            return youtubeRegex.test(url);
        },
        embedYouTubeUrl(url) {
            const videoId = this.getYouTubeVideoId(url);
            return `https://www.youtube.com/embed/${videoId}`;
        },
        getYouTubeVideoId(url) {
            const youtubeRegex =
                /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
            const match = url.match(youtubeRegex);
            if (match && match[3]) {
                return match[3];
            }
            return "";
        },
        isVideoUrl(url) {
            const videoExtensions = [".mp4", ".avi", ".mov", ".wmv"];
            const extension = url.substr(url.lastIndexOf(".")).toLowerCase();
            return videoExtensions.includes(extension);
        },
        isImageUrl(url) {
            const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
            const extension = url.substr(url.lastIndexOf(".")).toLowerCase();
            return imageExtensions.includes(extension);
        },
    selectStudent(student) {
      localStorage.setItem("selected_student", JSON.stringify(student));
      this.$router.push("/student-application");
    },
    async getVideos() {
      let response = await get("high_school/help_guides/highschool");
      this.videos = response.message;
    },
  },

  async created() {
    await this.getVideos();
    //console.log(this.$endpoints)
    let school  = {}
    if(localStorage.getItem("schoolInfo") != 'undefined'){
       school = JSON.parse(localStorage.getItem("schoolInfo")||'{}');
    }
    let res = await get(
      this.$endpoints.students + "?high_school_id=" + school?.id
    );
    this.sLoading = false
    this.students = res.responseBody;
    let res2 = await get(
      this.$endpoints.students_programs + "?high_school_id=" + school?.id
    );
    this.pLoading =false
    
    this.students_program_applications = res2.message;

    res2 = await get(
      this.$endpoints.dashboard + "?high_school_id=" + school?.id
    );
    if(res2?.message){
      this.docFileCount.forEach((item,index)=>{
        this.docFileCount[index].count = res2.message[item.title.replaceAll(' ','_')]
      })
    }
  },
  computed: {
    trackingSummary() {
      return [];
      //return this.students.data
    },

    mailSummary() {
      return this.inbox?.slice(0, 5);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
$text-color: #1d2939;
$primary: #fac515;
$warning: #fdb022;
$scrollbar-thumb-bg: #dc6803;
$scrollbar-color: #f79009;
$gray: #d0d5dd;

.view-track {
  font-size: 14px !important;
  //transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0 $primary !important;
  -webkit-transition: ease-out 0.4s !important;
  -moz-transition: ease-out 0.4s !important;
  transition: ease-out 0.4s !important;

  &:hover {
    // background-color: $primary !important;
    color: rgb(245, 245, 245) !important;
    //transition: color 0.5s;
    box-shadow: inset 150px 0 0 0 $primary !important;
    cursor: pointer;
  }
}
.fs-14 {
  font-size: 14px !important;
}

div.help-videos-container {
    padding: 0 0 0 2rem;

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-bottom: 1px solid #f79009;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #f79009;
        border: 1px solid #f79009;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #f79009;
    }

    h3 {
        margin: 5rem 0rem 0rem;
    }

    .help-videos {
        width: 100%;
        display: flex;
        overflow-y: auto;
        flex-wrap: nowrap;
        margin-top: 2rem;
        margin-bottom: 1rem;

        &>div {
            // border: 1px solid rgb(214, 207, 207);
            // padding: 1rem;
            margin: 2rem 1rem;
            flex-grow: 2;

            div {
                border: 1px solid gray;
                padding: 1rem;
                width: 281px;
                height: 181px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #c4c4c4;
            }

            h3 {
                font-size: 1.3rem;
                font-weight: 500;
                margin-top: 1rem;
                color: #101828;
            }
        }
    }
}
@media screen and (max-width: 768px) {
  .w-md-18 {
    width: 170%;
  }
}
.p-02 {
  padding: 0.4em 0.4em !important;
}
.fs-6 {
  font-size: 0.8em !important;
}
.inboxImg {
  width: 39.92px;
  height: 40px;
}
.grey {
  color: #98a2b3;
}

.bg-white {
  background-color: #fff;
}

.student-app-heading {
  margin-top: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
  background-color: #fff;

  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0;
    line-height: 38px;
    color: $text-color;
  }
}

.student-app {
  &-card {
    box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
    background-color: #fff;
  }
}

.student-track {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
  background-color: #fff;
  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 38px;
    color: $text-color;
    margin-bottom: 0;
  }
}
.p-header {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 38px;
  color: $text-color;
  margin-bottom: 0;
}

button {
  font-size: 0.8rem;
}
</style>
