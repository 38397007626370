<template>
  <div class="accordion" id="accordionExample">
    <!-- First Item -->
    <div class="my-5 mx-5">
      <hr>
      <h5>Education</h5>
      <hr>
    </div>
    <div class="container px-0">
      <div class="my-5 mx-5">

        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">High School Name</span>
          <span class="col text-truncate p-0">{{ data?.high_school }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Country</span>
          <span class="col text-truncate p-0">{{ data?.high_school_country }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">City</span>
          <span class="col text-truncate p-0">{{ data?.high_school_city }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">High School Address</span>
          <span class="col text-truncate p-0">{{ data.high_school_address}}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Study Language</span>
          <span class="col text-truncate p-0">{{ data?.study_language }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Year of Graduation</span>
          <span class="col text-truncate p-0">{{data?.high_school_graduation_year}}</span>
        </h6>
        <!-- <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Major</span>
          <span class="col text-truncate p-0">{{data?.high_school_majors}}</span>
        </h6> -->
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Student ID Number</span>
          <span class="col text-truncate p-0">{{data?.student_id_number}}</span>
        </h6>

      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  }
}
</script>
  
<style lang="scss">

</style>