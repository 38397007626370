<template>
  <div class="container h-auto px-0 py-3">
    <!-- Read-Only Form -->
    <div class="my-5 mx-5">
      <hr>
      <h5>Contact</h5>
      <hr>
    </div>
    <div class="container px-0">
      <div class="my-5 mx-5">

        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Country </span>
          <span class="col text-truncate p-0">{{ data?.citizen }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">City </span>
          <span class="col text-truncate p-0">{{ data?.city_region }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Home Address</span>
          <span class="col text-truncate p-0">{{ data?.home_address }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">National ID</span>
          <span class="col text-truncate p-0">{{ data?.passport_info?.country_local_id }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Passport Number</span>
          <span class="col text-truncate p-0">{{ data?.passport_info?.passport_id }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Postal Code</span>
          <span class="col text-truncate p-0">{{ data?.postal_code }}</span>
        </h6>
        <!-- <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Mobile Number</span>
          <span class="col text-truncate p-0">{{ data?.phone }}</span>
        </h6> -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>
.container {
  margin-bottom: 60px;
}



.img-size {
  border-radius: 4px;
  background-color: gray;
  height: 80px;
  width: 60px;
}
</style>