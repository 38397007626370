import { defineStore } from 'pinia'


export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: {},
        school:{},
        chats:[],
        isLoading:false,
        selectedStudent:{}        
    }),
    getters : {
        getUser: (state) => {
            return state.user
        },
        getSchool: (state) => {
            return state.school
        }
    },
    persist: true,
    actions: {        
        setSelectedStudent(student) {
            this.selectedStudent = student;
        },
          
        setUser(userInfo) {
            this.user = userInfo
        },
        removeUser() {
            this.user = ""
        },        
        setSchool(school) {
            this.school = school
        }
    }
})
