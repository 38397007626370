//import { useUserStore } from '@/stores/userStore'


export default (to, from, next) => {
  
  const token = localStorage.getItem('user')
  //console.log(token)
  //alert(token)
  if (token) {   
    return to.fullPath == '/login'? next('/dashboard'): next();
  } 
  
  if(to?.meta?.skipAuth){
    next()
  }  
  return next('/login')
};

