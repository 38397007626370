<template>
  <div class="position-relative">
    <div class="px-4 py-3">
      <div class="col-12 d-flex justify-content-between">
        <div>
          <h3 class="fw-bold">Total Earnings</h3>
          <div class="fw-bold">
            <h1 class="d-inline fw-bold">2000</h1>
            <span class="fw-lighter"> usd</span>
          </div>
        </div>
        <button
          @click="showForm()"
          :class="{ active: trackForm }"
          class="filter-btn btn text-dark px-5 py-2 d-flex ripple align-self-start"
          style="background-color: #FAC515;"
        >
          <img :src="require('/src/assets/images/filter.svg')" />
          Filter
        </button>
      </div>
    </div>
    <div v-if="trackForm" class="px-4">
      <EarningsFilterOptions @onFilterEarnings="filterChange" />
    </div>
    <hr style="background: #B2B2B2; height: 5px;" />
    <div class="accordion mx-5" id="accordionExample">
      <div class="accordion-item shadow my-4" style="background-color: #fff" v-for="i of [1,2,3,4,5,6,7,8,9,10]" :key="i">
        <div class="d-flex px-3 py-3">
          <div class="col-12 d-flex align-items-center">
            <div class="col d-flex justify-content-between align-items-center">
              <div class="col-1 track-cell">
                <img
                  style="width: 50px; height: 50px; border-radius: 50px"
                  class=""
                  :src="require('/src/assets/images/d-board/avater-1.svg')"
                  alt=""
                />
              </div>
              <div class="text-secondary track-cell col-2 px-1">
                <span>Full Name</span>
                <p class="text-dark mb-0 text-truncate">full_name</p>
              </div>
              <div class="text-secondary track-cell col-2 px-1">
                <span>Email</span>
                <p class="text-dark mb-0 text-truncate">email</p>
              </div>
              <div class="text-secondary track-cell col-2 px-1">
                <span>Country </span>
                <p class="text-dark mb-0 text-truncate">country</p>
              </div>
              <div class="text-secondary track-cell col-1 px-1">
                <span>National ID</span>
                <p class="text-dark mb-0 text-truncate">
                  national_id
                </p>
              </div>
              <div class="text-secondary track-cell col-1 px-1">
                <span>Earnings</span>
                <p class="text-dark mb-0 text-truncate">$2000</p>
              </div>
              <div class="text-secondary track-cell col-1 px-1">
                <span>Payment </span>
                <p class="text-dark mb-0 d-flex align-items-center">
                  <span style="margin-right: 5px">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      border-radius="5px"
                      class="bi bi-circle"
                      :style="{
                        backgroundColor: getTextColor('pending'),
                        fill: getTextColor('pending'),
                        borderRadius: '5px',
                      }"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                    </svg>
                  </span>
                  <span
                    class="text-truncate"
                    :style="
                      'color:' + getTextColor(student?.application_status)
                    "
                  >
                    Pending
                  </span>
                </p>
              </div>
              <div class="col-2" style="text-align: end; width: fit-content;">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + i"
                  aria-expanded="true"
                  :aria-controls="'#collapse' + i"
                >
                  <span class="pe-3">View Data</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          :id="'collapse' + i"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
                <hr />
          <div class="accordion-body">
            <div class="d-flex mx-auto mb-3" style="max-width: fit-content;">
              <div style="margin-right: 110px;">
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">University Name:</h5>
                  <h5 class="text-end text-secondary">University of Waterloo</h5>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">Program Type:</h5>
                  <h5 class="text-end text-secondary">Bachelor Degree</h5>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">Status:</h5>
                  <h5 class="text-end text-secondary">Got Admissions</h5>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">Fee Submission Date:</h5>
                  <h5 class="text-end text-secondary">09/January/2023</h5>
                </div>
              </div>
              <div>
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">Payment Type:</h5>
                  <h5 class="text-end text-secondary">University of Waterloo</h5>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">Semester Name:</h5>
                  <h5 class="text-end text-secondary">Bachelor Degree</h5>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">Tution Fees:</h5>
                  <h5 class="text-end text-secondary">Got Admissions</h5>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <h5 class="me-5">Earnings:</h5>
                  <h5 class="text-end text-secondary">09/January/2023</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed bottom-0 shadow py-3" style="background-color: #fff; width: 100%;">
        <div class="px-5 col-12 d-flex align-items-center">
          <div class="col d-flex justify-content-between align-items-center">
            <div class="text-secondary fw-bold track-cell col-3 px-1">
              <span>Total Students</span>
              <p class="text-dark mt-1 mb-1 text-truncate">
                10
              </p>
            </div>
            <div class="text-secondary fw-bold track-cell col-3 px-1">
              <span>Start Date</span>
              <p class="text-dark mt-1 mb-1 text-truncate">
                DD-MM-YY
              </p>
            </div>
            <div class="text-secondary fw-bold track-cell col-3 px-1">
              <span>Close Date</span>
              <p class="text-dark mt-1 mb-1 text-truncate">
                DD-MM-YY
              </p>
            </div>
            <div class="text-secondary fw-bold track-cell col-3 px-1">
              <span>Total Earnings</span>
              <p class="text-dark mt-1 mb-1 text-truncate">
                $2,000
              </p>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import EarningsFilterOptions from "@/components/Global/EarningsFilterOptions.vue";
export default {
  name: "UserEarnings",
  components: { EarningsFilterOptions },
  data() {
    return {
      trackForm: false,
      trackingStatus: ["In Review", "Accepted", "Enrolled", "Rejected"],
    };
  },
  methods: {
    filterChange(data) {
      this.filters = {
        ...data,
        program_status: data.status,
        program_created_at: data.created_at,
      };
      delete this.filters.status;
      delete this.filters.created_at;
      this.fetchStudents();
    },
    showForm() {
      this.trackForm = !this.trackForm;
    },
    getTextColor(status) {
      try {
        return status.toLowerCase() == "success" || status == ""
          ? "#ffb300"
          : status.toLowerCase() === "approved"
          ? "#198754"
          : status.toLowerCase() === "pending"
          ? "#DC3545"
          : "#555555";
      } catch (e) {
        //console.log(e, status)
      }
      return "";
    },
  },
};
</script>

<style>
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #C3B5FD !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  z-index: 3;
  background-color: #C3B5FD;
  outline: 0;
  box-shadow: none !important;
}
.accordion-button {
  background-color: #eaecf0 !important;
  border-radius: 10px !important;
}
</style>
