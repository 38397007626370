<template lang="html">
  <div class="container-fluid h-100 p-0">
    <nav class="navbar navbar-expand-lg p-3 py-4">
      <div class="container-fluid align-items-center">
        <a class="navbar-brand" href="" @click="home">
          <img
            :src="require('/src/assets/images/logo-dark.svg')"
            alt="logo"
            width="210"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <v-GoogleTranslateSelect
                default-language-code="en"
                default-page-language-code="en"
                :fetch-browser-language="false"
                trigger="click"
                @select="handleGoogleTranslateSelect"
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="terms-privacy d-flex align-items-start">
      <div class="side-bar nav flex-column">
        <div class="terms-btn-wrapper">
          <div
            class="btn nav-pills col-12 d-flex"
            v-for="link in links"
            :key="link.id"
            :class="{ termsBtn: link.id === currentId }"
            @click="displayText(link.id, link.termsText)"
          >
            <span class="px-2" v-if="link.id === currentId">
              <img :src="require(`/src/assets/images/${link.img}`)" alt="" />
            </span>
            <span class="px-2" v-else>
              <img :src="require(`/src/assets/images/${link.imgBlack}`)" alt="" />
            </span>
            <span>{{ link.title }}</span>
          </div>
        </div>
      </div>

      <div
        id="privacyPolicy"
        class="main-bar position-relative mx-5"
        v-bind:class="{ fadeIn: animate }"
      >
        <h2 class="f-w-600">{{ termsDisplay.title }}</h2>
        <div class="terms-context">
          <p>
            {{ termsDisplay.textOne }} <br /><br />
            {{ termsDisplay.textTwo }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { termsAndCondition, privacyPolicy } from "@/data";

export default {
  data() {
    return {
      termsAndCondition,
      privacyPolicy,
      termsDisplay: "", // Start with an empty display
      links: [
        {
          id: 1,
          title: "Terms and conditions",
          termsText: termsAndCondition,
          img: "gavel-yellow.svg",
          imgBlack: "gavel-black.svg",
        },
        {
          id: 2,
          title: "Privacy Policy",
          termsText: privacyPolicy,
          img: "policy-yellow.svg",
          imgBlack: "policy-black.svg",
        },
      ],
      currentId: 1, // Default to Terms and Conditions
      animate: true,
    };
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    handleGoogleTranslateSelect() {
      // Handle Google Translate selection
    },
    displayText(id, termsText) {
      this.currentId = id;
      this.termsDisplay = termsText;

      // Add fade-in animation
      let mainBar = document.querySelector(".main-bar");
      if (!mainBar.classList.contains("fadeIn")) {
        mainBar.classList.add("fadeIn");
      }
      setTimeout(() => {
        mainBar.classList.remove("fadeIn");
      }, 1000);
    },
  },
  mounted() {
    // Display Terms and Conditions by default
    if (this.termsDisplay === "") {
      this.termsDisplay = this.termsAndCondition;
    }

    // Check for hash in URL to auto-display Privacy Policy
    const hash = this.$route.hash;
    if (hash === "#privacyPolicy") {
      this.displayText(2, this.privacyPolicy);
    }
  },
  computed: {
    animateClass() {
      return !this.animate;
    },
  },
};
</script>

<style scoped>
.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.termsBtn {
  background-color: #f8f9fa;
  border-left: 5px solid #ffc107;
  cursor: pointer;
}

.terms-context {
  margin-top: 20px;
}

.main-bar {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>
