<template lang="html">
  <section id="inbox-container">
    <h3 class="px-5 pb-3">
      My Messages
      <!-- <pButton label="Testing Prime Vue Button" /> -->
    </h3>
    <div class="message-container">
      <div class="inbox-nav">
        <ul class="create-btn bg-warning rounded mb-4 px-2 py-2 row ripple">
          <li type="button" class="" @click="()=> this.$router.push('/inbox/create')">
            <!-- <router-link to="/inbox/create"> -->

              <span class="col-2">
                <img
                :src="require('/src/assets/images/create_icon.png')"
                alt="create new mail"
                />
              </span>
              <span class="col-10 text-truncate"> Create New Message </span>
            <!-- </router-link> -->
          </li>
        </ul>
        <ul class="side-panel border rounded">
          <li
            class="side-panel-items py-2 px-3 ripple"
            :class="this.$route.fullPath == link.route ? 'active-link' : ''"
            v-for="link in inboxLinks"
            :key="link.id"
            type="button"
            @click="() => this.$router.push(`${link.route}`)"
          >
            <span class="mail-text">
              <img
                :src="require(`/src/assets/images/${link.icon}.png`)"
                alt=""
              />
              {{ link.text }}
            </span>
            <span class="mail-count">
              {{ link.count ? link.count : '' }}
            </span>
          </li>
        </ul>
      </div>
      <div class="inbox-main container px-0">
        <Transition  name="fade" mode="out-in">
          <router-view :key="routeKey"></router-view>       
        </Transition>
      </div>
    </div>
    <ConfirmDialog :style="{ width: '25vw' }"></ConfirmDialog>
    <Toast position="center" />
  </section>
</template>
<script>
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';

//import { Messages } from '@/data';
import { useUserStore } from '@/stores/user';
import { useChatsStore } from '@/stores/chats';
import { get } from '@/api/client';
export default {
  name: 'InboxView',
  components: { ConfirmDialog, Toast },
  data() {
    return {
      search: '',
      links: [
        {
          id: 2,
          text: 'Inbox',
          active: true,
          count: ' unread',
          icon: 'inbox_icon',
          route: '/inbox'
        },        
        {
          id: 4,
          text: 'Draft Messages',
          active: false,
          count: 2,
          icon: 'draft_icon',
          route: '/inbox/draft'
        },
        {
          id: 5,
          text: 'Sent',
          active: false,
          icon: 'sent_icon',
          route: '/inbox/sent'
        },
        {
          id: 5,
          text: 'Trash',
          active: false,
          icon: 'trash_icon',
          route: '/inbox/trash'
        },
      ],
      messages: [],
      userStore: useUserStore(),      
      routeKey:0
    };
  },  
  async created(){
    const chatStore = useChatsStore()
    let res = await get(this.$endpoints.chats_index)    
    this.messages = res.message
    chatStore.setChats(res.message?.chats) 
    chatStore.setDrafts(res.message?.drafts) 
    chatStore.setSents(res.message?.sents)     
    chatStore.setTrashed(res.message?.trashed) 
    this.routeKey +=1
  },
  methods: {
    deleTe(messageId) {
      this.$confirm.require({
        message: 'Do you want to delete the selected converstions?',
        header: 'Delete',
        acceptLabel: 'OK',
        rejectLabel: 'Cancel',
        accept: () => {
          //callback to execute when user confirms the action
          this.xMessage(messageId);
          this.$toast.add({
            severity: 'success',
            detail: 'Deleted',
            life: 3000,
          });
        },
      });
    },
    xMessage(obj) {
      return (this.messages = this.messages.filter(
        (res) => res.sender.username != obj.sender.username
      ));
    },
  },
  computed: {
    inboxLinks() {
      const links = [
        {
          id: 2,
          text: 'Inbox',
          active: true,
          count: ' unread',
          icon: 'inbox_icon',
          route: '/inbox'
        },        
        {
          id: 4,
          text: 'Draft Messages',
          active: false,
          count: 2,
          icon: 'draft_icon',
          route: '/inbox/draft'
        },
        {
          id: 5,
          text: 'Sent',
          active: false,
          icon: 'sent_icon',
          route: '/inbox/sent'
        },
        {
          id: 5,
          text: 'Trash',
          active: false,
          icon: 'trash_icon',
          route: '/inbox/trash'
        },
      ];
        let unread = 0;        
        unread = this.messages?.chats?.reduce((sum, item) => {
            return sum + Number(item.total_unread);
        }, 0);
        links[0]['count'] = unread + ' unread';        
        return links;
    },
    filteredMessages() {
      if (this.search.trim().length > 0) {
        return this.messages.filter(
          (res) =>
            res.sender.username
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            res.sender.email
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            res.body.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.messages;
    },
    totalMsgs() {
      return this.messages.length;
    },
    unreadMSgs() {
      return this.messages.filter((msg) => msg.isRead).length;
    },
  },
};
</script>

<style lang="scss">
  .not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  h3 {
    font-size: 2.5rem;
  }
}
section#inbox-container {
  // height: 100%;
  //background-color: red;
  //border-left: 1px solid rgba(128, 128, 128, 0.5);
  h3 {
    font-size: 2.2em;
    font-weight: 600;
  }
  div.message-container {
    display: flex;
    border-top: 2px solid rgba(128, 128, 128, 0.555);
    min-height: 100%;

    div.inbox-nav {
      padding: 1rem;
      border-right: 2px solid gray;
      flex: 1;

      .create-btn {
        background-color: #fdb022;
      }

      li {
        display: flex;
        justify-content: space-between;

        img {
          width: 1rem;
        }
      }
      .side-panel {
        li {
          border-bottom: solid 1px #dee2e6;
        }

        li:last-child {
          border-bottom: none;
        }
        li img {
          margin-right: 10px;
        }
        .side-panel-items {
          .mail-text {
            font-size: 14px;
          }
        }
      }
    }

    div.inbox-main {
      flex: 4;

      .search-container {
        background: #5d6b98;
        color: whitesmoke;
        font-size: 0.8rem;
        padding: 0.4rem 2.5rem;
        align-items: center;
        gap: 30px;

        .inbox-count {
          display: flex;
          gap: 15px;
        }
        .input-group {
          width: 70%;
        }
        .input-icon {
          position: absolute;
          //left: 2px;
          z-index: 5;
        }
        input {
          width: 70%;
          border: none;
          padding: 0.2rem;
          padding-left: 2.5em;
        }
      }
    }

    div.message-list-container {
      div.message-list-item {
        display: flex;
        align-items: center;
        font-size: 0.8rem !important;
        border-bottom: 2px solid rgba(165, 165, 165, 0.555);
        padding: 1rem;

        img {
          display: inline-block;
          border-radius: 100%;
          width: 3rem;
        }

        .sender_dp {
          padding: 0;
        }

        .sender_id {
          font-weight: bold;
          //padding-left: 1rem;
        }

        .excerpt {
          p {
            font-size: 0.8rem;
          }
        }

        div.i-mark {
          min-width: 5.5rem;
          display: flex;
          align-items: flex-end;
          text-decoration: underline;
          cursor: pointer;
          font-size: 12px;
          align-self: end;
        }

        div.time {
          display: flex;
          flex-direction: column;
          font-size: 12px;

          span {
            display: block;
            padding: 0.2rem;
            cursor: pointer;
            font-size: 12px;
          }
        }
      }
    }
  }
  .active-link {
          background-color: #5d6b98;
          color: #fff;
        }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>