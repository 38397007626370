<template lang="html">
  <div>
    
    <div class="d-md-lg-screens">
      <!-- <LangModel /> -->
      <OffCanvas />
      <div class="px-0 bg-light shadow-sm position-fixed sidenav col-18 vh-100">
        <SideBar />
      </div>
      <div v-if="$globals.loading" style="position: fixed;top:0px;height:100vh;background: #000b;z-index: 90000;" class="w-100 d-flex align-items-center justify-content-center">
        <span class="loader3"></span>
      </div>
      <div class="d-flex flex-lg-column justify-content-center">
        <main class="row overflow-hidden">
          <NavBar />
          <Transition  name="scale" mode="out-in">
            <div :key="$route.path" class="col mt-6 py-5 container-fluid outer">
              <router-view></router-view>
            </div>
          </Transition>          
          <!-- <div class="col mt-6 py-5 container-fluid">
          </div> -->
        </main>
      </div>
    </div>
    <!-- <MobileWarnCard /> -->
  </div>
</template>


<script>
import NavBar from '@/components/Global/NavBar';
//import MobileWarnCard from '@/components/Global/MobileWarnCard';
import OffCanvas from '@/components/Global/OffCanvas';
import SideBar from '@/components/Global/SideBar.vue';
import { get } from '@/api/client';
export default {
  components: { NavBar, OffCanvas,  SideBar },
  watch: {
    '$globals.reloadNotification'() {
      this.fetchNotifications()
    },
    '$globals.loading':function(value){
      if(value){
        document.querySelector('body').style.overflow = "hidden"   
      }
    }
  },
  methods:{
    async fetchNotifications(){
      const user = JSON.parse(localStorage.getItem('userInfo'));
      let res = await get(this.$endpoints.notifications+'/'+user?.id);      
      if(res?.message){                    
        this.$globals.notification = res
      }
    }
  },

  async created() { 
    this.fetchNotifications()
  },
  mounted(){ 
          window.Echo.channel('notification').listen('.NewNotification', (data) => {                       
              this.$globals.notification.total_unread +=1
              this.$globals.notification.message.data.unshift(data)              
          });        
          window.Echo.connector.pusher.connection.bind('connected', () => {
            console.log('Laravel Echo connected');  
          });     
  }
};
</script>
<style scoped>
.mt-6 {
  margin-top: 6rem;
}
</style>
<style>
@media only screen and (max-width: 600px) {
.notification-text{
  font-size: 0.85em;
}
.notification li:first-child{
  display:flex;
  flex-direction: column;
  align-items: center;
}
.notification li:nth-child(2){
  margin:0px auto !important;
}
}

@media screen and (max-width: 992px) {
  .sidenav {
    display: none !important;
  }

  main {
    width: 100%;
  }

  .w-81-4 {
    width: 100%;
  }

  .menu-toggle-btn {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .col-18 {
    width: 18.7% !important;
    top: 0;
    left: 0;
  }

  .sidenav {
    display: block !important;
  }

  main {
    margin-left: 18.7% !important;
    width: calc(100% - 18.7%);
  }

  .w-81-4 {
    width: 81.4%;
  }

  .menu-toggle-btn {
    display: none;
  }
}

.loader3 {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  color: #FFF;
  box-sizing: border-box;
  animation: animloader3 1s linear infinite alternate;
}

@keyframes animloader3 {
  0% {
    box-shadow: -38px -6px, -14px 6px,  14px -6px;
  }
  33% {
    box-shadow: -38px 6px, -14px -6px,  14px 6px;
  }
  66% {
    box-shadow: -38px -6px, -14px 6px, 14px -6px;
  }
  100% {
    box-shadow: -38px 6px, -14px -6px, 14px 6px;
  }
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

</style>
