<template>
  <div>
    <div class="px-4 py-3">
      <div class="col-12 row">
        <div class="d-flex col-md-10 mb-3">
          <!-- <button @click="$router.go(-1)" class="btn ripple me-2 btn-sm btn-dark">Back</button> -->
          <h3 class="fw-bold" v-if="!viewShowStudentApplication">Student Applications</h3>
          <h5 v-else class="fw-bold flex items-center">
            <span @click="viewShowStudentApplication = false" style="text-decoration: underline; cursor: pointer;"
              @mouseover="$event.target.style.textDecoration = 'none'"
              @mouseout="$event.target.style.textDecoration = 'underline'">Student Applications</span>
            <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-chevron-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
              </svg></i> {{ selectedStudent?.full_name }}
          </h5>
        </div>
        <div class="col-md-2" v-show="!viewShowStudentApplication">
          <button @click="showForm()" :class="{ active: stuForm }"
            class="filter-btn btn text-dark px-5 py-2 d-flex ripple">
            <img :src="require('/src/assets/images/filter.svg')" />
            Filter
          </button>
        </div>
      </div>
    </div>
    <div v-if="!viewShowStudentApplication" class="row w-90 mx-auto">
      <!-- <div class="container-fluid h-100 p-0" style="background-color: aqua"> -->
      <div v-if="stuForm" class="px-4">
        <FilterOptions :statuses="studentStatus" @onSearch="filterChange" />
      </div>
      <hr />

      <div class="student-app px-5 mb-5 mx-auto position-relative" style="overflow:auto;">
        <div v-for="(student, index) in students?.data" :key="index">
          <StudentApplication @onClick="selecteStudent($event, student)" :student="student" />
        </div>
      </div>
      <PaginateData :paginationData="students" @onFetch="nextPage" :filters="filters" method="post" />
    </div>
    <div v-else>
      <StudentSidebar :student="selectedStudent" @back="back($event)" />
    </div>
  </div>
</template>

<script>

import StudentApplication from '../../components/StudentApplication/StudentApplication.vue'
import StudentSidebar from '../../components/StudentApplication/StudentSidebar.vue'
import FilterOptions from '@/components/Global/FilterOptions.vue';
import PaginateData from '@/components/Global/Pagination.vue';
import { post } from '@/api/client';
export default {
  components: { StudentApplication, StudentSidebar, FilterOptions, PaginateData },
  data() {
    return {
      viewShowStudentApplication: false,
      stuForm: false,
      selectedStatus: null,
      studentStatus: ['Rejected', 'Approved', 'In Review'],
      firstName: '',
      lastName: '',
      emailAddr: '',
      students: [],
      filters: {},
    };
  },
  async created() {
    this.checkFromDashboard()
    if (!this.students.length) {
      this.fetchStudents()
    }
  },
  methods: {
    nextPage(data) {
      this.students = data;
    },
    async fetchStudents() {
      const school = JSON.parse(localStorage.getItem('schoolInfo'))
      let filters = this.filters;
      filters.high_school_id = school?.id;
      let res = await post(this.$endpoints.students, { filters });
      this.students = res.message
    },
    filterChange(data) {
      this.filters = data
      this.fetchStudents();
    },
    checkFromDashboard() {

      let selected_student = JSON.parse(localStorage.getItem('selected_student'))
      if (selected_student) {
        this.selectedStudent = selected_student
        this.viewShowStudentApplication = true
        localStorage.removeItem('selected_student');
      }
    },
    selecteStudent(index, student) {
      this.selectedStudent = student
      this.viewShowStudentApplication = true;
    },

    showForm() {
      this.stuForm = !this.stuForm;
    },

    ClearForm() {
      this.firstName = '';
      this.lastName = '';
      this.emailAddr = '';
      this.selectedStatus = null;
    },

  },
};
</script>

<style lang="scss" scoped>
$primary: #fac515;
$warning: #fdb022;
$scrollbar-thumb-bg: #dc6803;
$scrollbar-color: #f79009;
$gray: #d0d5dd;

::v-deep(#inputStatus.p-dropdown) {
  .p-dropdown-items {
    background-color: red !important;
    border-bottom: 1px solid #fac515;
  }

  .p-dropdown-panel .p-dropdown-items .p-highlight {
    color: #495057;
    background: #f70909;
  }

  // .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  //   color: #5de40f;
  //   background: #e70c30;
  // }
}

/** input field hack to ensure the clear btn within the input fields are always circular **/
.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.trackform-input {
  input {
    padding-right: 40px;
  }

  .form-btna {
    position: absolute;
    right: 10px;
    z-index: 5;
    padding: 4px;
    border: 1px solid #98a2b3;
    border-radius: 100%;
    font-size: 10px;
    background-color: #98a2b3;
    color: white;
  }
}

::v-deep(#inputStatus.p-dropdown-panel) {
  .p-dropdown-items {
    background-color: red !important;
    border-bottom: 1px solid #fac515;
  }

  .p-dropdown-items .p-highlight {
    color: #495057;
    background: #f70909 !important;
  }
}

.active {
  background-color: $primary !important;
}

.filter-btn {
  background-color: $gray;
}

.text-xs {
  font-size: 0.85rem !important;
}</style>