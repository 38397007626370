
export const endpointRoutes = {        
        login:'high_school/login_hs',
        logout:'high_school/logout_hs',
        otp:'high_school/otp_hs',
        forgot:'high_school/forgot_hs',
        changeUserPassword:'high_school/change_password_hs',
        resetPassword:'high_school/reset_hs',
        refresh:'high_school/refresh_hs',
        upload:'high_school/upload_ep',
        students:'high_school/students/applications',
        students_programs: 'high_school/listall-student',
        studentById:'api/get-student/',
        generalSetting:'high_school/general_settings',
        programmes:'university/programmes',
        updateStudentStatus:'high_school/application/update-status',
        chats_index:'high_school/chat',
        chats:'high_school/chats',
        chat_mark_as_read:'high_school/chat/read',
        sendMessage:'high_school/chat/send',
        createTicket:'high_school/tickets',
        replyTicket:'high_school/tickets/reply',
        updateTicketStatus:'high_school/tickets/update_status',
        fetchTickets:'high_school/tickets/<USERID>/highschool/<STATUS>',
        fetchTicketCategoryOptions:'api/tickets_categories',
        notifications:'high_school/notifications',
        markNotificationAsRead:'api/notifications/mark_as_read',
        fileUpload:'high_school/file_upload',
        uploadProfilePhoto:'high_school/upload_profile_photo',
        dashboard:'high_school/student/application/dashboard',
        chatCode:'high_school/chats/code',
        getAssociations:'high_school/get_associations',
        joinAssociation:'high_school/join_association',
        leaveAssociation:'high_school/leave_association',
        getJoinedAssociations:'high_school/joined_associations',
        submitDocument:'high_school/update_document'
    }
