<template lang="html">
  <div>
    <div class="d-flex flex-column flex-grow-1 align-items-center text-white sticky-top" style="height: 81vh;"> 
      <div class="align-items-center w-100">
        <div class="dashboard-nav h-auto py-3 ps-3">
          <div class="rounded-circle px-2">
            <img class="rounded-circle " :src="userInfo?.high_school?.cover_photo_url || require('/src/assets/images/d-board/avater.svg')" alt="logo" width="45" />
            <div class="pt-2">
              <span class="fs-6">{{ userInfo.unique_id }}</span>
            </div>
            <div
              class="pt-1 d-flex flex-lg-row justify-content-between lg-align-items-center"
            >
              <div class="pt-0">
                <small class="fw-light"  lang="en" translate="no">{{ userInfo.email }}</small>
              </div>
              <div class="pt-0 cursor" @click="setting()">
                <small class="fw-light">
                  <img :src="require('/src/assets/images/d-board/settings.svg')"
                    alt="logo" /><span class="px-2">Settings</span></small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 overflow-auto dashboard-fixed">
        <div
          class="admin-wrapper bg-light flex-column  flex-nowrap  mb-0 justify-content-center align-items-center"
        >
          <div
            class="btn nav nav-pills my-1 align-items-center d-flex d ms-3 me-1 hovered justify-content-start sidenav-hover"
            v-for="link in links"
            :key="link.id"
            :class="{ adminBtn: link.id === currentId }"
            @click="displayView(link.id, link.termsText, link.linkRoute)"
          >
            <span class="pe-3" v-if="link.id === currentId">
              <img
                :src="require(`/src/assets/images/d-board/${link.img}`)"
                alt=""
                class="icon-size"
              />
            </span>
            <span class="pe-2" v-else>
              <img
                :src="require(`/src/assets/images/d-board/${link.imgBlack}`)"
                alt=""
                class="icon-size"
              />
            </span>
            <span style="font-size: 15px;margin-top: 4px;" :class="{'text-warning':link.id === currentId}">{{ link.title }}</span>
          </div>
        </div>
        <!-- <div class="px-3 pt-2">
          <div class="py-sm-4 admin-wrapper ms-auto flex-shrink-1">
            <div
              class="btn nav nav-pills d-flex my-4 ms-3 hovered"
              v-for="link in links.slice(6)"
              :key="link.id"
              @click="displayView(link.id, link.termsText, link.linkRoute)"
            >
              <span class="pe-3">
                <img
                  :src="require(`/src/assets/images/d-board/${link.imgBlack}`)"
                  alt=""
                  class="icon-size"
                />
              </span>
              <span>{{ link.title }}</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style scoped>
.dashboard-fixed::-webkit-scrollbar,
.sidenav::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.sidenav::-webkit-scrollbar {
  background-color: #aaa;
}

.sidenav::-webkit-scrollbar-thumb {
  background: #000;
}

.sidenav-hover:hover {
  color: #fac515;
}
</style>
<script>
import { useAuthStore } from "@/stores/auth";
export default {
  name: "SideBar",
  data() {
    return {
      animate: true,
      currentId: 1,
      userInfo: {},
      links: [
        {
          id: 1,
          title: "Dashboard",
          img: "dash-b-o.svg",
          imgBlack: "dash-b-black.svg",
          linkRoute: "/dashboard",
        },
        {
          id: 2,
          title: "Student Applications",
          img: "student-application-o.svg",
          imgBlack: "student-application-black.svg",
          linkRoute: "/student-application",
        },
        {
          id: 3,
          title: "Associations ",
          img: "association-o.svg",
          imgBlack: "association-black.svg",
          linkRoute: "/associations",
        },
        {
          id: 4,
          title: "Student Tracking ",
          img: "student-tracking-o.svg",
          imgBlack: "student-tracking-black.svg",
          linkRoute: "/student-tracking",
        },
        /*   {
                    id: 4,
                    title: "Inbox",
                    img: "inbox-o.svg",
                    imgBlack: "inbox-black.svg",
                    linkRoute: "/inbox",
                }, */
        {
          id: 5,
          title: "Live Chat",
          img: "chat-highlighted.svg",
          imgBlack: "chat.svg",
          linkRoute: "/chats",
        },
        {
          id: 6,
          title: "Earnings",
          img: "earnings.svg",
          imgBlack: "earnings-black.svg",
          linkRoute: "/earnings",
        },
        {
          id: 7,
          title: "Help and Support",
          img: "help-support-o.svg",
          imgBlack: "help-support-black.svg",
          linkRoute: "/help-and-support",
        },
        {
          id: 8,
          title: "Logout",
          img: "logout.svg",
          imgBlack: "logout.svg",
          linkRoute: "/login",
        },
      ],
      newRoute: "",
      termsDisplay: "",
    };
  },
  methods: {
    displayView(linkId, linkTerms, link) {
      let value = { linkId, linkTerms, link };
      this.handleDisplayText(value);
    },
    handleDisplayText(value) {
   
      let linkId, linkTerms, link;
      [linkId, linkTerms, link] = Object.values(value);
      this.currentId = linkId;
      this.termsDisplay = linkTerms;
      this.linkRoute = link;
      //remove user token from localstorage before loggin out
      if (link == "/login") {
        const AuthStore = useAuthStore();
        AuthStore.logout();
        this.$router.push('/login')
      }
      this.$router.push(`${this.linkRoute}`);
      // console.log(this.linkRoute, linkId, linkTerms, link);
    },
    setting() {
      this.$router.push("/settings");
    },
    getLocation() {
      let location = window.location.pathname.split("/")[1];
      let linkId = this.links.find((el) => el.linkRoute === `/${location}`);
      this.currentId = linkId != undefined ? linkId.id : null;
      return linkId;
    },
  },
  watch:{
    '$route.path':function(newdata, old){
      let link = this.links.find((item)=> item.linkRoute === newdata)      
      this.displayView(link?.id,link?.title,link?.linkRoute);
      old
    },
  },
  computed: {
    animateClass() {
      return !this.animate;
    },
  },
  created() {},
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getLocation();
  },
};
</script>
