
<template>
  <div>
    <div class="my-5 mx-5" >
    <hr>
    <h5>Profile</h5>
    <hr>
  </div>


    <div class="conatainer px-0">
             <div class="my-5 mx-5 ">

              <h6 class="card-title text-dark px-2 py-3 row mb-0">
                <span class="text-secondary col-5 text-truncate p-0"
                  >Full Name </span
                >
                <span class="col text-truncate p-0">{{ data?.full_name }}</span>
               </h6>
          <!--      <h6 class="card-title text-dark px-2 py-3 row mb-0">
                <span class="text-secondary col-5 text-truncate p-0"
                  >Last  Name </span
                >
                <span class="col text-truncate p-0">John</span>
               </h6> -->
               <h6 class="card-title text-dark px-2 py-3 row mb-0">
                <span class="text-secondary col-5 text-truncate p-0"
                  >Father's Name </span>                
                <span class="col text-truncate p-0">{{data?.father_name}}</span>
               </h6>
               <h6 class="card-title text-dark px-2 py-3 row mb-0">
                <span class="text-secondary col-5 text-truncate p-0"
                  >Mother's Name </span
                >
                <span class="col text-truncate p-0">{{ data?.mother_name }}</span>
               </h6>
               <h6 class="card-title text-dark px-2 py-3 row mb-0">
                <span class="text-secondary col-5 text-truncate p-0"
                  >Date Of Birth </span
                >
                <span class="col text-truncate p-0">{{ data?.dob }}</span>
               </h6>
               <h6 class="card-title text-dark px-2 py-3 row mb-0">
                <span class="text-secondary col-5 text-truncate p-0"
                  >Gender</span
                >
                <span class="col text-truncate p-0">{{ data?.gender }}</span>
               </h6>
               <!-- <h6 class="card-title text-dark px-2 py-3 row mb-0">
                <span class="text-secondary col-5 text-truncate p-0"
                  >Email Address</span
                >
                <span class="col text-truncate p-0">{{ data?.email }}</span>
               </h6> -->
           
             </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {      
    }    
  },
  created(){    
  
  }
  }
  </script>
  
  <style lang="scss">
  
  
  
  </style>











  