<template>
  <div class="accordion-item shadow my-3" style="background-color: #fff">
    <div class="d-flex px-3 py-2" style="height: 80px">
      <div class="col-12 d-flex align-items-center">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div class="col-1 track-cell">
            <img
              style="width: 50px; height: 50px; border-radius: 50px"
              :src="
                association?.logo
                  ? association?.logo
                  : require('/src/assets/images/d-board/avater-1.svg')
              "
              alt=""
            />
          </div>
          <div class="text-secondary track-cell col-3 px-1">
            <span class="t-head">Association Name</span>
            <p class="text-dark mb-0 text-truncate">
              {{ association?.name }}
            </p>
          </div>
          <div class="text-secondary track-cell col-2 px-1">
            <span class="t-head">Email Address</span>
            <p class="text-dark mb-0 text-truncate">
              {{ association?.email }}
            </p>
          </div>
          <div class="text-secondary track-cell col-2 px-1">
            <span class="t-head">Country </span>
            <p class="text-dark mb-0 text-truncate">
              {{ association?.country }}
            </p>
          </div>
          <div class="text-secondary track-cell col-2 px-1">
            <span class="t-head">City</span>
            <p class="text-dark mb-0 text-truncate">
              {{ association?.city }}
            </p>
          </div>
          <div class="text-secondary track-cell col-1 px-1">
            <span class="t-head">Status</span>
            <p class="text-dark mb-0 text-truncate">
              <span style="margin-right: 5px">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" border-radius="5px" viewBox="0 0 16 16">
                   <circle cx="8" cy="8" r="7" :fill="getTextColor(association?.active)" />                   
                </svg>
              </span>
              
              {{ association?.active == "1" ? "Active" : "Inactive" }}
            </p>
          </div>
          <div class="col-1 d-flex" style="text-align: end; width: fit-content">
            <button
              class="btn btn-sm btn-warning py-2 px-3 ripple"
              style="font-size: 16px"
              type="button"
              @click="
                accordBtn(
                  request
                    ? 'collapse' + association?.id
                    : 'collapse-req' + association?.id
                )">
              <span class="">View</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="
        request
          ? 'collapse' + association?.id
          : 'collapse-req' + association?.id
      "
      class="accordion-collapse collapse"
      data-bs-parent="#accordionExample"
    >
      <hr />
      <div class="accordion-body px-5">
        <Transition name="fade">
          <div class="row gx-5" v-if="visibility" >
            <div class="col-lg-6 offset-lg-3">
              <div class="card p-3">
                <h4>High School Information</h4>
                <div class="d-flex align-items-center justify-content-between line-heig">
                  <h6 class="type">Name:</h6>
                  <h6 class="type-content">
                    {{ association?.name }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between line-heig">
                  <h6 class="type">Type:</h6>
                  <h6 class="type-content">
                    {{ association?.type }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between line-heig">
                  <h6 class="type">Country:</h6>
                  <h6 class="type-content">
                    {{ association?.country }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between line-heig">
                  <h6 class="type">Total Members:</h6>
                  <h6 class="type-content">
                    {{ association?.members }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between line-heig">
                  <h6 class="type">Registered Date:</h6>
                  <h6 class="type-content">
                    {{ association?.registered_date }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between line-heig">
                  <h6 class="type">City:</h6>
                  <h6 class="type-content">
                    {{ association?.city }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between line-heig">
                  <h6 class="type">Association Address</h6>
                  <h6 class="type-content">
                    {{ association?.address }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>

          </div>
        </Transition>

        <div class="d-flex flex-column align-items-center my-4">
          <button
          v-if="request && association?.request"
            class="button mb-2 ripple"
            :disabled="full"
            style="background: #f1c111"
            @click="cancelRequest"
          >
          Cancel Request Association
          </button>
          <button
          v-if="request"
            class="button mb-2 ripple"
            :disabled="full"
            style="background: #f1c111"
            @click="join"
          >
            Request to join Association
          </button>
          <button
          v-if="!request"
            class="button mb-2 ripple"
            :disabled="full"
            style="background: #f1c111"
            @click="leave"
          >
          Leave Association
          </button>
          
          <p v-if="full" class="note">
            Exit one of your current associations to become a member of this
            association. Contact
            <span class="support" @click="$router.push('/help-and-support')"
              >support</span
            >
            to exit one of your association
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "@/api/client";
import jquery from "jquery";
const $ = jquery;
import { showModal } from "@/plugins/modal";

export default {
  name: "AsociationTile",
  props: {
    association: {
      type: Object,
    },
    request: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibility:false,
      cardKey:0,
      userInfo: JSON.parse(localStorage.getItem("userInfo") || "{}"),
    };
  },
  methods: {
    getTextColor(status) {
      try {
        return status !== '1' || status == ''
          ? '#bbb'
          :'#198754'           
      } catch (e) {
        //console.log(e, status)
      }
      return '';
    },
    accordBtn(id) {
      this.visibility = !this.visibility
      //setTimeout(() => {        
        this.cardKey++
        $("#" + id).slideToggle(100);
      //}, 300);
    },
    async join() {
      let a = await showModal({
        title: "Confirmation",
        text: "Are you sure you want to join this association?",
        cancelText: "No",
        confirmText: "Confirm",
        cancelBackground: "#fff",
        cancelOutline: "warning",
      });
      if (a) {
        let resp = await post(this.$endpoints.joinAssociation, {
          association_id: this.association.id,
          high_school_id: this.userInfo.high_school.id,
        });
        if (resp?.message) {
          this.$emit("join", this.association);
        }
      }
      a = await showModal({
        text: 'Your request has been successfully sent. You will receive a notification upon confirmation.',
        confirmText: 'HS Association List',
        cancelText: 'Close',
        cancelBackground: '#fff',
        cancelOutline: 'warning',
        icon: 'success'
        })

        if (a) {
          this.$router.push('/associations')
        }
    },
    async cancelRequest() {
      let a = await showModal({
        title: "Confirmation",
        text: "Are you sure you want to cancel the request?",
        cancelText: "No",
        confirmText: "Confirm",
        cancelBackground: "#fff",
        cancelOutline: "warning",
      });
      if (a) {
        let resp = await post(this.$endpoints.joinAssociation, {
          association_id: this.association.id,
          high_school_id: this.userInfo.high_school.id,
        });
        if (resp?.message) {
          this.$emit("join", this.association);
        }
        return false
      }
      a = await showModal({
        text: 'Your request has been cancelled ',
        confirmText: 'HS Association List',
        cancelText: 'Close',
        cancelBackground: '#fff',
        cancelOutline: 'warning',
        icon: 'error'
        })

        if (a) {
          this.$router.push('/associations')
        }
    },
    async leave() {
      let a = await showModal({
        title: "Confirmation",
        text: "Are you sure you want to leave the association?",
        cancelText: "No",
        confirmText: "Confirm",
        cancelBackground: "#fff",
        cancelOutline: "warning",
      });
      if (a) {
        let resp = await post(this.$endpoints.leaveAssociation, {
          association_id: this.association.id,
          high_school_id: this.userInfo.high_school.id,
        });
        if (resp?.message) {
          this.$emit("leave", this.association);
        }
        return false
      }
      a = await showModal({
        text: 'Your request has been cancelled ',
        confirmText: 'HS Association List',
        cancelText: 'Close',
        cancelBackground: '#fff',
        cancelOutline: 'warning',
        icon: 'error'
        })

        if (a) {
          this.$router.push('/associations')
        }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #c3b5fd !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  z-index: 3;
  font-size: 12px !important;
  background-color: #c3b5fd;
  outline: 0;
  box-shadow: none !important;
}
.accordion-button {
  background-color: #eaecf0 !important;
  border-radius: 10px !important;
}
.track-cell {
  font-size: 12px;
}
.track-cell p {
  font-size: 14px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}

.t-head {
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
}
.card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(16, 24, 40, 0.16);
}
.type {
  color: var(--base-black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}
.type-content {
  color: #1d2939;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}
.button {
  padding: 10px 15px;
  border-radius: 10px;
  outline: none;
  border: none;
  width: 30%;
}

.note {
  color: var(--gray-800, #1d2939);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 191.667% */
}
.support {
  font-weight: 600;
  text-decoration-line: underline;
}
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.line-heig h6{
  margin: 2px;
  font-size: 14px;
}
</style>
