<template lang="html">
    <section id="inbox-container">

      <div class="message-container">

        <div class="inbox-main container px-0">
          <div class="search-container d-flex">
            <div class="inbox-count">
              <span
                ><img :src="require(`/src/assets/images/sent_icon.png`)" alt=""
              /></span>
              <span>Sent</span>
            </div>
            <!-- <div class="relative"> 
                    <button class="input-icon">
                      <img :src="require(`/src/assets/images/search_icon.png`)" alt="" />
                    </button>
              <input type="text" />
              </div> -->
  
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  type="button"
                  class="input-icon input-group-text bg-transparent border-0"
                  id="basic-addon1"
                >
                  <img
                    :src="require(`/src/assets/images/search_icon.png`)"
                    alt=""
                /></span>
              </div>
              <input
                type="text"
                name="search"
                class="form-control border-left-0"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                v-model.trim="search"
              />
            </div>
          </div>
          <div class="message-list-container container px-0">
            <template v-if="filteredMessages.length > 0">
              <div
                class="message-list-item row mx-0"
                v-for="(message, index) in filteredMessages"
                :key="index"
              >
                <div class="sender_dp col-1">
                  <!-- <img :src="message.sender.imgURL" alt="sender dp" width="56" /> -->
                  <span class="position-relative">
                    <img :src="message.imgURL" alt="sender dp" />

                  </span>
                </div>
                <div class="sender_id col-3">
                  <div class="text-truncate">{{ message.username }}</div>
                  <div class="text-truncate">{{ message.email }}</div>
                </div>
                <div class="excerpt col-7">
                  <span class=""
                    >{{ message.body.slice(0, 100) }}
                    {{ message.body.length > 70 ? '...' : null }}</span
                  >
                </div>

                <div class="time col-1">
                  <span>Recent</span>
                  <span
                    @click="deleTe(message)"
                    class="text-danger text-decoration-underline"
                    >Delete</span
                  >
                </div>
              </div>
            </template>
            <template v-else>
              <div class="not-found">
                <img
                  class="mb-3"
                  :src="require('@/assets/images/no-user-found.png')"
                />
                <h3>No results found</h3>
                <p>It seems we can't find any results based on your search.</p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <ConfirmDialog :style="{ width: '25vw' }"></ConfirmDialog>
      <Toast position="center" />
    </section>
  </template>
  <script>
  import ConfirmDialog from 'primevue/confirmdialog';
  import Toast from 'primevue/toast';
  import { Mail } from '@/data';
  export default {
    name: 'SentMail',
    components: { ConfirmDialog, Toast },
    data() {
      return {
        search: '',
        messages: '',
      };
    },
    methods: {
      deleTe(messageId) {
        this.$confirm.require({
          message: 'Do you want to delete the selected converstions?',
          header: 'Delete',
          acceptLabel: 'OK',
          rejectLabel: 'Cancel',
          accept: () => {
            //callback to execute when user confirms the action
            this.xMessage(messageId);
            this.$toast.add({
              severity: 'success',
              detail: 'Deleted',
              life: 3000,
            });
          },
        });
      },
      xMessage(obj) {
        return (this.messages = this.messages.filter(
          (res) => res.username != obj.sender.username
        ));
      },
    },
    computed: {
      filteredMessages() {
   
        if (this.search.trim().length > 0) {
          return this.messages.filter(
            (res) =>
              res.username
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              res.email
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              res.body.toLowerCase().includes(this.search.toLowerCase())
          );
        }
        return this.messages;
      },
      
    },
     serverPrefetch() {
    // component is rendered as part of the initial request
    // pre-fetch data on server as it is faster than on the client
    this.messages = Mail.sent
  },
    mounted() {
      if(this.$route.fullPath == '/inbox/trash'){
          return this.messages = Mail.trash
        }
        if(this.$route.fullPath == '/inbox/draft'){
          return this.messages = Mail.draft
        }
        return this.messages = Mail.sent
    }
  };
  </script>
  <style lang="scss">
  .not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;
    h3 {
      font-size: 2.5rem;
    }
  }
  </style>
  <style lang="scss">

  </style>