<template lang="html">
  <section id="applications-container">
      <h3 class="fw-bold px-4 py-3" >Student Applications</h3>
      <hr>
      <div class="message-container">
          <div class="applications-nav">
            
            <ul class="nav nav-pills py-5" id="pills-tab" role="tablist" style="align-items: flex-end;    display: flex;
    flex-direction: column;">
      <li class="nav-item m-3" role="presentation">

        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"  >Profile</button>
          
       </li>
      <li class="nav-item m-3" role="presentation">
        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"  >Contact</button>
      </li>
      <li class="nav-item m-3" role="presentation">
        <button class="nav-link" id="pills-education-tab" data-bs-toggle="pill" data-bs-target="#pills-education" type="button" role="tab" aria-controls="pills-education" aria-selected="false"  >Education</button>
      </li>
      <li class="nav-item m-3" role="presentation">
        <button class="nav-link" id="pills-document-tab" data-bs-toggle="pill" data-bs-target="#pills-document" type="button" role="tab" aria-controls="pills-document" aria-selected="false" >Documents</button>
      
    </li>
            </ul>
          </div>
          <div class="applications-main tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
          <StudentBio :data="student"/>
      </div>
      <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
      <StudentContact :data="student?.contant"/>
      </div>
      <div class="tab-pane fade" id="pills-education" role="tabpanel" aria-labelledby="pills-education-tab" tabindex="0">
        <StudentEducation :data="student?.education"/>
      </div>
      <div class="tab-pane fade" id="pills-document" role="tabpanel" aria-labelledby="pills-document-tab" tabindex="0">
        <StudentDocument :data="student?.document" />
      </div>
    </div>
      </div>
  </section>
</template>
<script>
  // import NavBar from "@/components/navBar";
  
  
 
  import StudentDocument from "@/components/StudentApplication/StudentDocument.vue";
import StudentContact from "@/components/StudentApplication/StudentContact.vue";
import StudentEducation from "@/components/StudentApplication/StudentEducation.vue";
import StudentBio from "@/components/StudentApplication/StudentBio.vue";
import { useUserStore } from "@/stores/user";
  export default {
    name: "StudentSidebar",
    components: { StudentContact, StudentDocument, StudentEducation,  StudentBio },
    data() {
      return {
        student:[]
      }
    },
    created(){
      this.student = useUserStore().selectedStudent;
    }
  }
  </script>
<style lang="scss">

$grey-500: #667085;
$grey-800: #1D2939;
section#applications-container {
  border-left: 1px solid rgba(128, 128, 128, 0.5);
  border-left: none;
  div.message-container {
      display: flex;
      border-top: none;
      border-left: none;
      
      div.applications-main{
          flex: 1
      }

      div.applications-nav {
          padding: 0;
          border-right: 1px solid #667085;

    width: 15%;

          .nav-link{
            color: $grey-500;
            &.active{
              color: $grey-800!important;
              font-weight: 600;
            }
          }

          

          li {
              display: flex;
              justify-content: space-between;
              
              img {
                  width: 1rem;
              }
          }
          .nav-link > button{
                color: red;
          }
          .nav-link.active{
            background-color: #F9FAFB;
                 color: black;
                
                 
          }
      }

      

      div.message-list-container {
          div.message-list-item {
              display: flex;
              font-size: .1rem;
              border-bottom: 2px solid rgba(165, 165, 165, 0.555);
              padding: 1rem;

              .nav-link{
                color: green;
                background-color: red;
             }

              div.sender_id {
                  font-weight: bold;
                  padding-left: 1rem;
              }

              div.excerpt {
                  p {
                      font-size: .1rem;
                  }
              }
            

          }
     
      }
      
      .application-review{
       

        border-radius: 10px;
        border: 1px solid gray;
        width: 100%;
      }
  }
  
}
</style>