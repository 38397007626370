<template lang="html">
    <section id="inbox-container">

      <div class="message-container">

        <div class="inbox-main container px-0">
          <div class="search-container d-flex">
            <div class="inbox-count">
              <span
                ><img :src="require(`/src/assets/images/unread_icon.png`)" alt=""
              /></span>
              <span>Inbox {{ totalMsgs }}</span>
            </div>
            <!-- <div class="relative"> 
                    <button class="input-icon">
                      <img :src="require(`/src/assets/images/search_icon.png`)" alt="" />
                    </button>
              <input type="text" />
              </div> -->
  
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  type="button"
                  class="input-icon input-group-text bg-transparent border-0"
                  id="basic-addon1"
                >
                  <img
                    :src="require(`/src/assets/images/search_icon.png`)"
                    alt=""
                /></span>
              </div>
              <input
                type="text"
                name="search"
                class="form-control border-left-0"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                v-model.trim="search"
              />
            </div>
          </div>
          <div class="message-list-container container px-0">
            <template v-if="filteredMessages.length > 0">
              <div class="message-list-item row mx-0"
                v-for="(message, index) in filteredMessages" :style="+message?.is_read==1?'':'background:aliceblue'"
                :key="index" > 
                <div @click="openMessage(message.sender_id, message.user_type,index)" class="sender_dp col-1">
                  <!-- <img :src="message.sender.imgURL" alt="sender dp" width="56" /> -->
                <span class="position-relative" >
                  <img :src="message.profile_photo_url? message.profile_photo_url
                        : require('/src/assets/images/d-board/avater-1.svg')"  alt="sender dp" />

                  <span
                    v-if="message?.is_read"
                    class="badge position-absolute translate-custom-middle p-1 bg-orange rounded-pill"
                  >
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </span>
                </div>
                <div @click="openMessage(message.sender_id, message.user_type,index)" class="sender_id col-3">
                  <div class="text-truncate">{{ message.full_name }}</div>
                  <div class="text-truncate">{{ message.sender_email }}</div>
                </div>
                <div  @click="openMessage(message.sender_id, message.user_type,index)" class="excerpt col-5" >
                  <span class=""
                    >{{ message.message?.slice(0, 100) }}
                    {{ message.message?.length > 70 ? '...' : null }}</span
                  >
                </div>
                <div class="i-mark col-2">
                  <span @click="markAsRead(message.sender_id, message.user_type, index)"
                    >Mark as read</span
                  >
                </div>
                <div class="time col-1">
                  <span>Recent</span>
                  <span
                    @click="deleTe(message)"
                    class="text-danger text-decoration-underline"
                    >Delete</span
                  >
                </div>
              </div>
            </template>
            <template v-else>
              <div class="not-found">
                <img
                  class="mb-3"
                  :src="require('@/assets/images/no-user-found.png')"
                />
                <h3>No results found</h3>
                <p>It seems we can't find any results based on your search.</p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <ConfirmDialog :style="{ width: '25vw' }"></ConfirmDialog>
      <Toast position="center" />
    </section>
  </template>
  <script>
  import ConfirmDialog from 'primevue/confirmdialog';
  import Toast from 'primevue/toast';
  import { useChatsStore } from '@/stores/chats';
  //import { Messages } from '@/data';
  import {get} from "@/api/client";
  export default {
    name: 'MailInbox',
    components: { ConfirmDialog, Toast },
    data() {
      return {
        search: '',
        chatStore: useChatsStore(),
        messages: [],
      };
    },
    async mounted(){
      setTimeout(() => {        
        this.messages = this.chatStore.chats
      }, 200);
    //  const response = await get(this.$endpoints.chats_index)

      //this.messages = response?.chats_index
      ///console.log(this.messages)
    },
    methods: {
      openMessage(sender_id,user_type,index){                
        this.chatStore.setSelectedChatInfo(this.messages[index])
        this.$router.push(`inbox/${sender_id}-${user_type}`)
      },
      async markAsRead(sender_id,user_type,index){
        await get(`${this.$endpoints.chat_mark_as_read}?sender_id=${sender_id}&user_type=${user_type}`)
        this.messages[index].is_read = 1;
      },
      deleTe(messageId) {
        this.$confirm.require({
          message: 'Do you want to delete the selected converstions?',
          header: 'Delete',
          acceptLabel: 'OK',
          rejectLabel: 'Cancel',
          accept: () => {
            //callback to execute when user confirms the action
            this.xMessage(messageId);
            this.$toast.add({
              severity: 'success',
              detail: 'Deleted',
              life: 3000,
            });
          },
        });
      },
      xMessage(obj) {
        return (this.messages = this.messages.filter(
          (res) => res.sender.username != obj.sender.username
        ));
      },
    },
    computed: {      
      filteredMessages() {
        if(this.$route.fullPath == '/inbox#unread'){
          return this.messages.filter(res => res.isRead == true)
        }
        if (this.search.trim().length > 0) {
          return this.messages.filter(
            (res) =>
              res.sender.username
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              res.sender.email
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              res.body.toLowerCase().includes(this.search.toLowerCase())
          );
        }
        return this.messages;
      },
      totalMsgs() {
        if(this.$route.fullPath == '/inbox#unread'){
          return this.messages.filter(res => res.isRead == true).length
        }
        return this.messages?.length;
      },
      unreadMSgs() {
        return this.messages.filter((msg) => msg.isRead).length;
      },
    },
  };
  </script>
  <style>
  .message-list-item{
    cursor: pointer;
  }
  .message-list-item:hover{
    background: rgb(255, 255, 255) !important;
  }
  div.inbox-nav{
    border-right: 2px solid #e6e6e6 !important;    
  }
  </style>