export let termsAndCondition = {
  title: "Terms and conditions",
  textOne: `Applying to Estonian universities requires acceptance of the following
        conditions: I confirm that all statements I make in my application are correct,
        truthful and completeI am aware that in compliance with the General Data
        Protection Regulation, the university has the right to receive and process my
        personal data (e.g. name, date of birth, personal identification code,
        citizenship, previous learning and contact information) in order to perform
        tasks carried out in public interests and comply with legal obligations deriving
        from the Estonian legislation.I understand and agree that the university may
        disclose the data to other bodies outside of the university for the purposes of
        verifying my identity and qualification(s). Such bodies include but are not
        limited to:prior education institution(s) the applicant has attended or
        graduated from;
        `,
  textTwo: `Estonian ENIC/NARIC Centre (http://adm.archimedes.ee/enic/en/); Estonian
        Ministry of Foreign Affairs. I am informed that written materials provided in
        support of the application must be authentic and may be subject to review for
        the purpose of identifying plagiarism.I am aware that the university reserves
        the right to withdraw or amend any offer or revoke my matriculation as a student
        of the university, if it becomes evident that the application contains
        fraudulent information, my qualification does not provide access to the chosen
        study programme or I am found to have omitted key information from the
        application. Should such circumstances occur, the
        `,
};

export let privacyPolicy = {
  title: "Privacy Policy",
  textOne: ` Estonian universities requires acceptance of the following 
  conditions: I confirm that all statements I make in my application are correct, 
  truthful and completeI am aware that in compliance with the General Data Protection 
  Regulation, the university has the right to receive and process my personal data 
  (e.g. name, date of birth, personal identification code, citizenship, previous 
      learning and contact information) in order to perform tasks carried out in 
      public interests and comply with legal obligations deriving from the Estonian 
      legislation.I understand and agree that the university may disclose the data to 
      other bodies outside of the university for the purposes of verifying my identity
       and qualification(s). Such bodies include but are not limited to:prior education 
       institution(s) the applicant has attended or graduated from;
        `,
  textTwo: `Estonian ENIC/NARIC Centre (http://adm.archimedes.ee/enic/en/); Estonian
        Ministry of Foreign Affairs. I am informed that written materials provided in
        support of the application must be authentic and may be subject to review for
        the purpose of identifying plagiarism.I am aware that the university reserves
        the right to withdraw or amend any offer or revoke my matriculation as a student
        of the university, if it becomes evident that the application contains
        fraudulent information, my qualification does not provide access to the chosen
        study programme or I am found to have omitted key information from the
        application. Should such circumstances occur, the
        `,
};

export const Students = [
  {
    id: 1,
    fullName: "Ivar Lothbrok",
    email: "ivar@vikings.com",
    avatar: require("@/assets/images/d-board/avater-1.svg"),
    country: "Scotland",
    nationalID: "318735",
    status: "Got Admissions",
    details: [
      {
        university: "Warton Üniversitesi",
        major: "Battle",
        country: "Scotland",
        submittedDate: "23-12-22",
        staus: "Progress",
        isAccepted: "Progress",
        accUniversity: "Not Available",
      },
      {
        university: "Koc Üniversitesi",
        major: "Martial Arts",
        country: "Scotland",
        submittedDate: "Progress",
        staus: "Got Admissions",
        isAccepted: "admiited",
        accUniversity: "Not Available",
      },
      {
        university: "Vikings College",
        major: "Martial Arts",
        country: "Scotland",
        submittedDate: "23-10-22",
        staus: "progress",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Warton Üniversitesi",
        major: "Battle",
        country: "Scotland",
        submittedDate: "23-12-22",
        staus: "Progress",
        isAccepted: "Progress",
        accUniversity: "Not Available",
      },
      {
        university: "Nail Üniversitesi",
        major: "BSc Artificial Intelligence",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
  {
    id: 2,
    fullName: "Wajahat Samil",
    email: "wahajat@highschool.com",
    avatar: require("@/assets/images/d-board/john-doe.png"),
    country: "Turkey",
    nationalID: "3122735",
    status: "Nill",
    details: [
      {
        university: "Sabanci Üniversitesi",
        major: "BSc Cyber Security",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
  {
    id: 3,
    fullName: "Michal Malewicz",
    email: "helloMalewicz@outlook.com",
    avatar: require("@/assets/images/d-board/avater.svg"),
    country: "Azherbaijan",
    nationalID: "318735",
    status: "Applyings",
    details: [
      {
        university: "Sabanci Üniversitesi",
        major: "BSc Cyber Security",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Koc Üniversitesi",
        major: "HCI",
        country: "Turkey",
        submittedDate: "nill",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Vikings College",
        major: "Martial Arts",
        country: "Scotland",
        submittedDate: "23-10-22",
        staus: "progress",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Cambridge Üniversity",
        major: "BSc Cyber Security",
        country: "Uk",
        submittedDate: "23-10-23",
        staus: "Progress",
        isAccepted: "Progress",
        accUniversity: "Not Available",
      },
      {
        university: "Nail Üniversitesi",
        major: "BSc Artificial Intelligence",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },

  {
    id: 4,
    fullName: "Usi Anone",
    email: "usi@new.com",
    avatar: require("@/assets/images/d-board/avater-2.svg"),
    country: "Marvel",
    nationalID: "3132735",
    status: "Nill",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },

  {
    id: 5,
    fullName: "Azeez AMos",
    email: "azeez@email.com",
    avatar: require("@/assets/images/d-board/katemoris.png"),
    country: "Nigeria",
    nationalID: "213452",
    status: "No Apply",
    details: [
      {
        university: "Nail Üniversitesi",
        major: "BSc Artificial Intelligence",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Sabanci University",
        major: "BSc Cyber Security",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
      {
        id: 2,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 3,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 4,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
    ],
  },
  {
    id: 6,
    avater: "avater.svg",
    fullName: "Ajnur Bakary",
    email: "anjurbekarys@gmail.com",
    avatar: require("@/assets/images/d-board/avater-2.svg"),
    country: "Turkey",
    national_id: 435256,
    status: "Approved",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "0.20mb",
        date: "25/05/2022",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "4.00mb",
        date: "8/4/2022",
      },
      {
        id: 3,
        text: "Profile Credentials",
        size: "100kb",
        date: "6/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "5/05/2022",
      },
      {
        id: 5,
        text: "National ID Photo",
        size: "4.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 7,
    avater: "avater-2.svg",
    fullName: "Thomas kelvin",
    email: "thomass@gmail.com",
    avatar: require("@/assets/images/d-board/kamal.png"),
    country: "London",
    national_id: 123456,
    status: "Waiting Review",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "1.00mb",
        date: "2/9/2021",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "0.50mb",
        date: "12/04/2021",
      },
      {
        id: 3,
        text: "Id Card",
        size: "500Kb",
        date: "9/07/2021",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "1/06/2021",
      },
      {
        id: 5,
        text: "Country ID Card",
        size: "1.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 8,
    avater: "avater.svg",
    fullName: "Okoli james",
    email: "okolithomas@gmail.com",
    avatar: require("@/assets/images/d-board/esma.png"),
    country: "Nigeria",
    national_id: 935456,
    status: "Rejected",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
];

export const StudentApplications = [
  {
    id: 1,
    avater: "avater.svg",
    fullName: "Ajnur Bakary",
    email: "anjurbekarys@gmail.com",
    avatar: require("@/assets/images/d-board/avater-2.svg"),
    country: "Turkey",
    national_id: 435256,
    status: "Approved",
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "0.20mb",
        date: "25/05/2022",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "4.00mb",
        date: "8/4/2022",
      },
      {
        id: 3,
        text: "Profile Credentials",
        size: "100kb",
        date: "6/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "5/05/2022",
      },
      {
        id: 5,
        text: "National ID Photo",
        size: "4.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 2,
    avater: "avater-2.svg",
    fullName: "Thomas kelvin",
    email: "thomass@gmail.com",
    avatar: require("@/assets/images/d-board/avater-1.svg"),
    country: "London",
    national_id: 123456,
    status: "Waiting Review",
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "1.00mb",
        date: "2/9/2021",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "0.50mb",
        date: "12/04/2021",
      },
      {
        id: 3,
        text: "Id Card",
        size: "500Kb",
        date: "9/07/2021",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "1/06/2021",
      },
      {
        id: 5,
        text: "Country ID Card",
        size: "1.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 3,
    avater: "avater.svg",
    fullName: "Okoli james",
    email: "okolithomas@gmail.com",
    avatar: require("@/assets/images/d-board/avater.svg"),
    country: "Nigeria",
    national_id: 935456,
    status: "Rejected",
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
];

export const Messages = [
  {
    sender: {
      id: 1,
      username: "Kate Morison",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
    },
    receivedAt: "21/05/2022",
    isRead: true,
    body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 2,
      username: "Dowe John",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/john-doe.png"),
    },
    receivedAt: "21/05/2022",
    isRead: false,
    body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 3,
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
    },
    receivedAt: "21/05/2022",
    isRead: true,
    body: "Based on your requirements - Lorem Ipsum fhrc ckead Jdwijd veroiersdc awdaw e fsvoer sepe",
    attachments: ["screen-shot.jpg"],
  },
];

export const Mail = {
  inbox: [
    {
      id: 1,
      username: "Kate Morison",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
      receivedAt: "21/05/2022",
      isRead: true,
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 2,
      username: "Dowe John",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/john-doe.png"),
      receivedAt: "21/05/2022",
      isRead: false,
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 3,
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedAt: "21/05/2022",
      isRead: true,
      body: "Based on your requirements - Lorem Ipsum fhrc ckead Jdwijd veroiersdc awdaw e fsvoer sepe",
      attachments: ["screen-shot.jpg"],
    },
  ],
  draft: [],
  sent: [
    {
      id: 1,
      username: "Kate Morison",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
      sentAt: "21/05/2022",
      subject: "Pending Approval",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 2,
      username: "Dowe John",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/john-doe.png"),
      sentAt: "2/12/2022",
      subject: "Application accepted to Univerity of France",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 3,
      username: "Laravel Dell",
      email: "dell@example.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      sentAt: "3/12/2022",
      subject: "Application accepted to Univerity of France",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 4,
      username: "Isaac Newton",
      email: "isaac@nentonnetwork.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedAt: "3/12/2022",
      subject: "Electricity Request",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
  ],
  trash: [],
};

export const FAQ = [
  {
    question: "I'm having problems logging in",
    active: false,
    answer: `<li class="mt-1">• Make sure you are entering the correct login credentials(user ID and password).Double check for any typos or capitalization errors.</li>
    <li class="mt-1">• If you're still unable to log in, try resetting your password using the "Forgot Password" option on the login page.</li>`,
  },
  {
    question: "I forget my password",
    active: false,
    answer: `<li class="mt-1">• On the login page, click on the "Forgot Password" link. Enter your registered
email address, and instructions for resetting your password will be sent to your email.</li>
    <li class="mt-1">• If you don't receive the email, check your spam folder or contact Paramount
Student Support for further assistance.</li>`,
  },
  {
    question: "How can I contact paramount student support team",
    active: false,
    answer: `<li class="mt-1">• You can contact Paramount Student Support by visiting our website and
navigating to the "Contact Us" page. There, you'll find contact information such as
email, phone, and possibly a live chat option.</li>`,
  },
  {
    question: "How can I evaluate the student Document",
    active: false,
    answer: `<p>Once logged into the high school dashboard, navigate to the student's profile or
application section.</p>
     <li class="mt-1">• Look for the section related to documents or uploads, where you should be able
to review the documents submitted by the student.</li>
    <li class="mt-1">• Use the provided evaluation tools or guidelines to assess the documents based
on the criteria or requirements established by the platform or university.</li>`,
  },
  {
    question: "How can I track students' activity",
    active: false,
    answer: `<li class="mt-1">• Within the high school dashboard, you should have access to a student activity
tracking feature or module.</li>
    <li class="mt-1">• This feature allows you to view a student's progress, actions, and engagement on
the platform, such as application submissions, document uploads, communication
history, etc.</li>
<li class="mt-1">• Look for tabs or sections labelled "Activity," "Progress," or "History" to access this
information.</li>`,
  },
  // {
  //   question: "I'm having problems in Sign Up",
  //   active: false,
  //   answer:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  // },
  {
    question: "What is paramount students contact support toll free number",
    active: false,
    answer: `<li class="mt-1">• To obtain the toll-free number for Paramount Student Support, please visit our
website and navigate to the "Contact Us" page. There, you'll find the appropriate
contact details, including any toll-free numbers, if available.</li>`,
  },
  {
    question: "Can high schools update student information on the platform?",
    active: false,
    answer: `<li class="mt-1">• No, high schools do not have the ability to update student information on the
Paramount Students platform. Their role is primarily to confirm the student's enrollment
at their institution and provide necessary documentation or verification as requested.</li>`,
  },
  {
    question:
      "What actions can high schools take regarding student applications?",
    active: false,
    answer: `<li class="mt-1">• High schools can review and evaluate student applications submitted through the
Paramount Students platform.</li>
<li class="mt-1">• They have the authority to approve or reject applications based on their
assessment of the student's eligibility and compliance with the school's admission
Criteria.</li>
`,
  },
  {
    question:
      "How can high schools confirm a student's enrollment on the platform?",
    active: false,
    answer: `<li class="mt-1">• High schools can verify a student's enrollment by accessing the student's profile
or application on the dashboard.</li>
<li class="mt-1">• There may be a designated section or option to confirm the student's enrollment
status, typically with a checkbox or button.</li>
<li class="mt-1">• By selecting the appropriate confirmation option, high schools can indicate that
the student is indeed enrolled at their institution.</li>
`,
  },
  {
    question: "What happens if a high school rejects a student's application?",
    active: false,
    answer: `<li class="mt-1">• If a high school rejects a student's application, the student will be notified of the
rejection through the platform.</li>
<li class="mt-1">• The student can then explore other options and universities available on the
Paramount Students platform to continue their application process</li>
`,
  },
  {
    question:
      "Can high schools earn revenue through the Paramount Students platform?",
    active: false,
    answer: `<li class="mt-1">• Yes, high schools have the opportunity to earn revenue through the platform.</li>
<li class="mt-1">• By referring their students to universities through the Paramount Students
platform, high schools can earn a commission for each successful enrollment of their
students in a university.</li>
`,
  },
  {
    question:
      "How can high schools communicate with students who have contacted their Schools?",
    active: false,
    answer: `<li class="mt-1">• The Paramount Students platform provides a communication feature for high
schools to interact with students.</li>
<li class="mt-1">• Within the high school dashboard, administrators can access the communication
section or inbox to view and respond to messages received from students</li>
`,
  },
  {
    question:
      "Are there any training or resources available for high school administrators using the platform?",
    active: false,
    answer: `<li class="mt-1">• Paramount Students may provide training materials, tutorials, or resources to
support high school administrators in using the platform effectively.</li>
<li class="mt-1">• These resources can help administrators navigate the dashboard, understand the
features, and utilize the tools provided</li>
`,
  },
  {
    question: "How to sign-up process in this school application system?",
    active: false,
    answer: `<li class="mt-1">• We do not accept individual school registrations. Instead, we have a sales representative visit
high schools to gather information and request our Paramount Students technical department to
create an ID using the school's information provided to our sales representative.</li>
<li class="mt-1">• To initiate the sign-up process, please feel free to contact our sales team through the cell
phone number provided on our website, as well as the email address listed.
We will be happy to assist you with any questions you may have and guide you through the
registration process.</li>
`,
  },
];

export const Chats = [
  {
    sender: {
      id: 1,
      username: "Kate Morison",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
    },
    receivedAt: "12:23pm",

    body: "Hello i applied for the programme please let me know if you required additional document",
  },
  {
    sender: {
      id: 2,
      username: "Dowe John",

      imgURL: require("@/assets/images/d-board/john-doe.png"),
    },
    receivedAt: "today",

    body: "let me check",
  },
  {
    sender: {
      id: 3,
      username: "Kamal Surya",

      imgURL: require("@/assets/images/d-board/avater.svg"),
    },
    receivedAt: "21/05/2022",

    body: "Based on your requirements - Lorem Ipsum fhrc ckead Jdwijd veroiersdc awdaw e fsvoer sepe",
  },
];
