<template>
  <div class="container h-auto px-0 py-3">
    <div class="applications-main">


      <div class="my-5 mx-5 ">
        <hr>
        <div class="d-flex justify-content-between">
          <h5>Documents</h5>
          <button v-if="Object.keys(data).length >0" class="btn btn-secondary" @click="downloadFilesAsZip">Download documents</button>
        </div>
        <hr>
      </div>
      <div class="container">

        <div>

          <div class="row d-flex flex-row">
            <div class="col-lg-8">
              <div class="row d-flex flex-row " style="align-items: center;"  v-for="(name,i) in Object.keys(data)" :key="i">
                <div class="col-1  m-6">
                  <div class="m-2">
                    <img class="img-size" v-if="fileType(data[name]) ==='pdf'" :src="require('/src/assets/images/pdf.png')" alt="">
                    <img class="img-size" v-if="fileType(data[name]) ==='image'" :src="data[name]"  alt="">
                  </div>
                </div>
                <div class="col-6 m-2" style="padding-left: 60px">
                  <h6 class="m-1">{{documents[name]|| 'others'}}</h6>
                  <p class="m-1 text-secondary text-xs">                    
                  </p>          
                </div>
                <div class="col-1" style="padding-left: 10px">
                  <button @click="viewDocument(data[name])" class="view-btn btn text-dark m-3 px-2 py-2 d-flex">
                    <img class="me-2" :src="require('/src/assets/images/d-board/view.svg')" alt="">
                    view</button>
                </div>
              </div>
              

            </div>
            <div class="col-lg-4">
              <div class="application-review p-3">
                <h6 class="text-center my-3">Application Review</h6>
                <div class="form-group sub-input sub-input-large">
                  
                    <div class="form-group dropdown">
                      <Dropdown style="border-radius: 10px; " v-model="selectedReview" :options="AppliactionReview" optionLabel=""
                placeholder="Waiting  Review" class="w-100" :default="AppliactionReview[0]">
                <template v-slot:option="{ option }">
                  <div>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" class="bi bi-circle custom-icon"
                        :style="{
                          backgroundColor: getIconColor(option),
                          fill: getIconColor(option),
                        }" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      </svg>
                      {{ option }}
                    </span>
                  </div>
                </template>
              </Dropdown>

                      <!-- <Dropdown style="border-radius: 10px;" v-model="selectedReview" :options="AppliactionReview"
                        optionLabel="" placeholder="Waiting  Review" class="w-100 " :default="AppliactionReview[0]" /> -->
                    </div>
                  
                  <div class="form-group">
                    <label class="label-text">Comments</label>
                    <textarea v-model="comment" class="form-control" placeholder="Enter a comment..." :autoResize="true" rows="4" cols="17"></textarea>
                  </div>
                  
                    <div class="btn-container">
                      <button @click="saveReview()" class="save-next-btn ripple text-dark ">
                        Save and Next
                      </button>
                    </div>
                  
                </div>
              </div>
            </div>

          </div>
        </div>

        <div v-if="showModal">
          <div class="modal-overlay" @click="closeModal"></div>
          <div class="modal-custom">
            <div class="modal-header">
              <button class="modal-close" @click="closeModal">X</button>
            </div>
            <div
              class="modal-body d-flex justify-content-center align-items-center"
              style="overflow: auto"
            >
              <iframe v-if="docType != 'image'" :src="doc"></iframe>
              <img v-else :src="doc" style="display: block;max-width:70%;width: fit-content;" id="imageViewer" />
            </div>
          </div>
      </div>
    </div>

  </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import { post } from '@/api/client';
import {showModal} from "@/plugins/modal";
import JSZip from 'jszip';
import { useUserStore } from '@/stores/user';
export default {
  name: "StudentDocument",
  components: { Dropdown },
  props: {
    data: {
      type: Object,
      required: true,
    },
    student:{
      type:Object,
      require:true
    }
  },
  data() {
    return {
      showModal: false,
      stuForm: false,      
      selectedReview:this.student?.application_status,
      AppliactionReview: [ 'In Review','Approved', 'Rejected'],
      comment:this.student?.comment,
      documents:{
          highschool_marksheet :'High School Transcript (Final Grade)' ,
          highschool_certificate :'High School Diploma/Certificate' ,
          formal_photo :'Formal Photo' ,
          local_id_card :'Local ID Card (Front and Back)' ,
          passport_front :'Passport (Front)'
      },
      docType:'image',
      studentData:null,

    };

  },
  methods: {
    
    getIconColor(status) {      
      return status.toLowerCase() == 'in review' ||status == ''
          ? '#009aff'
         
          : status.toLowerCase() === 'approved'
          ?'#ffb300'
          : status.toLowerCase() === 'rejected'?
          "#DC3545":'#555555';    
    },
    downloadFilesAsZip() {
      try{

      
      const store = useUserStore()
      store.isLoading =true
      
      const zip = new JSZip();

      // Function to fetch each file and add it to the zip archive
      const addFileToZip = (url, file_name) => {
              return fetch(url)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.blob();
                })
                .then((blob) => {
                  zip.file(file_name, blob);
                })
                .catch((error) => {
                  console.log('Error occurred:', error);
                });
            };


      // Create a promise for each file to be added to the zip archive
      
      const filePromises = Object.keys(this.data).map((key) => addFileToZip(this.data[key],this.documents[key]));

      // Wait for all promises to resolve
      Promise.all(filePromises)
        .then(() => {
          // Generate the zip file
          return zip.generateAsync({ type: "blob" });
        })
        .then((content) => {
          // Trigger the download of the zip file
          const zipFileName = "files.zip";
          const link = document.createElement("a");
          link.href = URL.createObjectURL(content);
          link.download = zipFileName;
          store.isLoading =false 
          link.click();
        });
      }catch(e){
        console.log(e)
      }
    },

    fileType(url) {      
        const PDfExtensions = [".pdf"];
        let extension = url.substr(url.lastIndexOf(".")).toLowerCase();
        if(PDfExtensions.includes(extension)){
          return 'pdf';
        }

        const videoExtensions = [".mp4", ".avi", ".mov", ".wmv"];
        extension = url.substr(url.lastIndexOf(".")).toLowerCase();
        if(videoExtensions.includes(extension)){
          return 'video';
        }
        const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
        extension = url.substr(url.lastIndexOf(".")).toLowerCase();
        if(imageExtensions.includes(extension)){
         return 'image';
        }
    },        
    viewDocument(doc) {
      this.showModal = true;
      this.docType = this.fileType(doc);
      this.doc = doc;
      if (this.docType == "image") {
        this.autoResizeImage();
      }
    },
    closeModal() {
      this.showModal = false;
      this.doc = "";
    },

    autoResizeImage() {
      // Get the image element
  /*     const image = document.getElementById("imageViewer");
      image.onload = () => {
        // Get the current width and height
        const currentWidth = image.width;
        const currentHeight = image.height;

        // Calculate the new width and height with 10% reduction
        const newWidth = currentWidth * 0.9;
        const newHeight = currentHeight * 0.9;

        // Set the new width and height
        image.style.width = newWidth + "px";
        image.style.height = newHeight + "px";
      }; */
    },
    async saveReview() {
     
      if(!this.selectedReview){        
        showModal({
          text:`select a status`,          
          confirmText: 'Ok',              
        });
        return false
      }
     let colorType =  this.selectedReview =='Approved'?'success': this.selectedReview =='Rejected'?'error':'warning';
     let confirm = await showModal({
          title:'Application Confirmation',
          text:`Are you sure you want to ${this.selectedReview} this student's application request status? `,          
          confirmText: 'Yes',
          confirmBackground:colorType,
          cancelText: 'Cancel',
          cancelBackground:'white',
          cancelOutline:'warning'          
        });
     if(!confirm){
      return false
     }
      post(this.$endpoints.updateStudentStatus,{
        status: this.selectedReview,
        comment:this.comment, 
        student_id: this.$props.student?.id
      }).then(response=>{              
        if(response){
          this.studentData.application_status = this.selectedReview          
          showModal({title:this.selectedReview,text:response,icon:colorType,confirmButton:false})
        }  
      })
    }

  },
  created() {   
    this.studentData = this.student 
  },

  mounted() {    
    this.$nextTick(()=>{
      if(this.student?.application_status){                
        this.selectedReview = this.student?.application_status       
        this.comment = this.student?.comment       
      }
    })

  }

};
</script>

<style scoped>

.container {
  margin-bottom: 60px;
}


.application-review div {
  margin-bottom: 10px;
}


.img-size {
  border-radius: 2px;  
  height: 80px;
  width: 70px;
}

.label-text {
  font-size: 15px;
}

.btn-container {
  padding: 2px;
}

.dropdown {

}

.view-btn {
  background-color: #EAECF0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .view-btn:hover {
    color: rgb(245, 245, 245) !important;
    box-shadow: inset 150px 0 0 0 #fac515 !important;
} */

.view-btn img {
  width: 20px;
}

.save-next-btn {
  border-radius: 4px;
  width: 100%;
  padding: 0.6rem;
  border: none;
  background-color: #FAC515;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.modal-custom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  border-radius: 5px;
  z-index: 11;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close {
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  height: 100%;
  background: white;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
